import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {SwapperComponent} from '../../../assets/ts/components'
import {KTSVG} from '../../../components'
import {toAbsoluteUrl} from '../../../helpers'
import {DefaultTitle, IHeader, Topbar, useLayout} from '../../../layout'
import {useAuth} from '../../../providers'

const calculateStickyOffset = (header: IHeader): string => {
  if (header.fixed.desktop && header.fixed.tabletAndMobile) {
    return '{default: "200px", lg: "300px"}'
  }

  if (header.fixed.desktop && !header.fixed.tabletAndMobile) {
    return '{lg: "300px"}'
  }

  if (header.fixed.tabletAndMobile && !header.fixed.desktop) {
    return '{default: "200px", lg: false}'
  }

  return ''
}

const calculateShowSticky = (header: IHeader): boolean => {
  return (
    (header.fixed.desktop && header.fixed.tabletAndMobile) ||
    (header.fixed.desktop && !header.fixed.tabletAndMobile) ||
    (header.fixed.tabletAndMobile && !header.fixed.desktop)
  )
}

export function HeaderWrapper() {
  const {config, attributes} = useLayout()
  const {header} = config
  const [stickyOffset, setStickyOffset] = useState<string>(calculateStickyOffset(header))
  const [showSticky, setShowSticky] = useState<boolean>(calculateShowSticky(header))
  const {currentUser} = useAuth()

  useEffect(() => {
    setStickyOffset(calculateStickyOffset(header))
    setShowSticky(calculateShowSticky(header))
  }, [header])

  return (
    <div
      id='gori_header'
      className='mx-10 mx-xxl-10'
      {...attributes.headerMenu}
      data-kt-sticky={showSticky ? 'true' : 'false'}
      data-kt-sticky-name='header'
      data-kt-sticky-offset={stickyOffset}
    >
      <div className='m-xxl-0 d-flex flex-stack flex-nowrap gap-2 mt-5' id='gori_header_container'>
        <DefaultTitle />
        {/* begin::Wrapper */}
        <div className='d-flex d-xxl-none align-items-center ms-n2 me-2'>
          {/* begin::Aside mobile toggle */}
          {currentUser && (
            <div className='btn btn-icon btn-active-icon-primary' id='gori_aside_toggle'>
              <KTSVG path='/media/gori/aside/toggle.svg' className='svg-icon-1 mt-1' />
            </div>
          )}
          {/* end::Aside mobile toggle */}

          {/* begin::Logo */}

          <Link
            to='/a1-batches'
            onClick={() => SwapperComponent.removeInstances()}
            className='d-flex align-items-center'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/bigShipbaeLogo.svg')}
              className='h-25px'
            />
          </Link>
          {/* end::Logo */}
        </div>
        {/* end::Wrapper */}
        <Topbar />
      </div>
    </div>
  )
}
