import clsx from 'clsx'
import {cloneDeep, difference, isEmpty, uniq} from 'lodash'
import React, {useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {useColumnOrder, useSortBy, useTable} from 'react-table'
import {MenuComponent} from '../../assets/ts/components'
import {KTSVG} from '../../components'
import {TABLE_KEY_COLUMN} from '../../constants'
import {reducerCases} from '../../context'
import {useDisabled} from '../../providers'
import {useStatesGlobalNoStorage} from '../../providers/StateProvider'
import {CustomsColumnTable, PageSize, PaginateModel, PaginateWidget} from '../../widgets'

type Props = {
  columns: any
  data: any
  pagination?: any
  usePagination?: boolean
  useCheckBox?: boolean
  useHeaderUpperCase?: boolean
  useSort?: boolean
  callBackSetData?: (selected: any) => void
  tableClass?: any
  tbodyClass?: any
  theadClass?: any
  removeClassDefaultHeader?: boolean
  customColumn?: {show: boolean; name: string}
}

const Table: React.FC<Props> = ({
  columns,
  data,
  pagination,
  usePagination = false,
  useCheckBox = false,
  useHeaderUpperCase = true,
  useSort = true,
  callBackSetData,
  tableClass,
  tbodyClass,
  theadClass,
  removeClassDefaultHeader = false,
  customColumn = {show: false, name: ''},
}) => {
  const initialState = {
    hiddenColumns: ['canCheck'],
  }
  const intl = useIntl()
  const {pageDisabled} = useDisabled()
  const [{isShowCustomColumns}, dispatch] = useStatesGlobalNoStorage()
  const [searchParams, setSearchParams] = useSearchParams()
  const [paginationInfo, setPaginationInfo] = useState<PaginateModel | null>(null)
  const [initRows, setInitRows] = useState<any>([])
  const [masterChecked, setMasterChecked] = useState(false)
  const [selectedRows, setSelectedRows] = useState<any>([])
  const columnsShow = useMemo(() => {
    if (customColumn.show) {
      return cloneDeep(columns).filter((item) => item?.show)
    } else {
      return columns
    }
  }, [columns, customColumn.show])

  const {
    headerGroups,
    rows,
    prepareRow,
    state: {sortBy},
  } = useTable(
    {
      columns: columnsShow,
      data,
      initialState,
      manualSortBy: true,
      disableSortBy: !useSort,
    },
    useSortBy,
    useColumnOrder
  )

  useEffect(() => {
    if (!isEmpty(pagination)) {
      setPaginationInfo(pagination)
    }
  }, [pagination])

  useEffect(() => {
    if (!isEmpty(rows)) {
      let tempRowsCanCheck = rows.filter((row) => row.values.canCheck)
      setInitRows(tempRowsCanCheck)
    } else {
      setInitRows({})
    }
  }, [rows])

  useEffect(() => {
    if (!isEmpty(selectedRows) && selectedRows.length === initRows.length) {
      setMasterChecked(true)
    }
    if (callBackSetData) {
      callBackSetData(selectedRows)
    }
  }, [callBackSetData, initRows, selectedRows])

  useEffect(() => {
    if (pageDisabled) {
      setMasterChecked(false)
      setSelectedRows([])
    }
  }, [pageDisabled])

  const handleSelect = (row: any) => {
    const isSelected = selectedRows.some((item: any) => item.index === row.index)
    if (isSelected) {
      setSelectedRows((prev) => prev.filter((selected) => selected.index !== row.index))
      setMasterChecked(false)
    } else {
      setSelectedRows([...selectedRows, row])
    }
  }

  useEffect(() => {
    if (masterChecked) {
      setSelectedRows(rows)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleSelectAll = () => {
    const tempRows = masterChecked ? difference(selectedRows, initRows) : initRows
    setSelectedRows(uniq(tempRows))
    setMasterChecked((prev) => !prev)
  }

  const updateSearchParams = (key: string, value: string | null) => {
    if (value !== null) {
      searchParams.set(key, value)
    } else {
      searchParams.delete(key)
    }
    setSearchParams(searchParams)
  }

  const handlePageSizeChange = (pageSize: any) => {
    const newPageSize = pageSize.value

    setPaginationInfo((prev) => ({
      page: 1,
      page_size: newPageSize,
      total: prev?.total || 0,
      total_pages: prev?.total_pages || 0,
    }))

    updateSearchParams('page', '1')
    updateSearchParams('page_size', String(newPageSize))
  }

  const handlePageChange = (page: number) => {
    setPaginationInfo((prev) => ({
      page,
      page_size: prev?.page_size || 10,
      total: prev?.total || 0,
      total_pages: prev?.total_pages || 0,
    }))

    updateSearchParams('page', page.toString())
  }

  useEffect(() => {
    if (sortBy.length > 0) {
      const {desc, id} = sortBy[0]
      updateSearchParams('sort_direction', desc ? 'desc' : 'asc')
      updateSearchParams('sort_by', id)
    } else {
      updateSearchParams('sort_direction', null)
      updateSearchParams('sort_by', null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy])

  useEffect(() => {
    if (rows?.length === 0) {
      return
    }
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [rows])

  const elementCustomsColumn = useMemo(() => {
    return customColumn.show ? (
      <th
        className={'text-center fixed-column'}
        onClick={() =>
          dispatch({type: reducerCases.IS_SHOW_CUSTOM_COLUMNS, isShowCustomColumns: true})
        }
      >
        <KTSVG
          path={`/media/gori/tables/customColumn.svg`}
          className={clsx('svg-icon svg-icon-8 p-2 rounded-2 cursor-pointer bg-hover-light', {
            'border border-2 border-primary text-blue': isShowCustomColumns,
          })}
        />
      </th>
    ) : null
  }, [customColumn.show, dispatch, isShowCustomColumns])

  return (
    <>
      {/* begin::Body */}
      <CustomsColumnTable
        name={customColumn.name}
        show={isShowCustomColumns}
        initColumns={cloneDeep(columns)}
        columns={cloneDeep(columnsShow)}
        handleClose={() => {
          dispatch({type: reducerCases.IS_SHOW_CUSTOM_COLUMNS, isShowCustomColumns: false})
        }}
      />
      {isEmpty(headerGroups) && (
        <div className='d-flex justify-content-end'>
          <div
            className='btn btn-sm btn-primary'
            onClick={() =>
              dispatch({type: reducerCases.IS_SHOW_CUSTOM_COLUMNS, isShowCustomColumns: true})
            }
          >
            {intl.formatMessage({id: 'MANAGE_COLUMNS'})}
          </div>
        </div>
      )}
      <div className='dataTables_wrapper dt-bootstrap4 no-footer d-flex flex-column flex-fill'>
        {/* begin::Table container */}
        {/* begin::Table */}
        <div className='scroll-x flex-fill scroll-y-clip'>
          <table
            className={clsx('table align-middle table-row-dashed fs-6 gy-4 dataTable no-footer', {
              'opacity-50': pageDisabled,
              [tableClass]: !!tableClass,
            })}
          >
            <thead className={theadClass}>
              {headerGroups.map((headerGroup, index) => (
                <tr
                  key={index}
                  className={
                    removeClassDefaultHeader
                      ? ''
                      : clsx('text-start text-muted fw-bolder fs-7 gs-0', {
                          'text-uppercase': useHeaderUpperCase,
                        })
                  }
                >
                  {useCheckBox && (
                    <th className='w-10px pe-2'>
                      {initRows.length > 0 && (
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input cursor-pointer'
                            type={'checkbox'}
                            checked={masterChecked}
                            onChange={handleSelectAll}
                          />
                        </div>
                      )}
                    </th>
                  )}
                  {headerGroup.headers.map((column, index) => {
                    const checkNoSubActions =
                      index === headerGroup.headers.length - 1 &&
                      headerGroup.headers[index].id !== TABLE_KEY_COLUMN.SUB_ACTIONS

                    if (column.id === TABLE_KEY_COLUMN.SUB_ACTIONS) {
                      return elementCustomsColumn
                    }

                    return (
                      <>
                        <th
                          {...column.getHeaderProps([
                            {
                              //@ts-ignore
                              colSpan: column.headerColSpan,
                              className: column.headerClassName,
                            },
                            column.getSortByToggleProps(),
                          ])}
                          key={column.id}
                        >
                          {column.render('Header')}
                          <span className='ms-2'>
                            {column.isSorted &&
                              (column.isSortedDesc ? (
                                <i className='fas fa-angle-down' />
                              ) : (
                                <i className='fas fa-angle-up' />
                              ))}
                          </span>
                        </th>
                        {checkNoSubActions && elementCustomsColumn}
                      </>
                    )
                  })}
                </tr>
              ))}
            </thead>
            <tbody className={tbodyClass}>
              {rows.map((row: any, index) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()} key={row.id || index}>
                    {useCheckBox && (
                      <td>
                        {row.values.canCheck && (
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input cursor-pointer'
                              type={'checkbox'}
                              checked={
                                masterChecked ||
                                !!selectedRows.find((item: any) => item.id === row.id)
                              }
                              onChange={() => handleSelect(row)}
                            />
                          </div>
                        )}
                      </td>
                    )}
                    {row.cells.map((cell: any, idx) => {
                      return (
                        <td
                          {...cell.getCellProps([
                            {
                              className: cell.column.cellClassName,
                            },
                          ])}
                          key={idx}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        {/* end::Table */}
        {/* end::Table container */}
        {/* begin::Paginate */}
        <div className='row mt-3'>
          <div className='col-auto d-flex align-items-center justify-content-start'>
            {paginationInfo && usePagination && !isEmpty(rows) && (
              <>
                <PageSize
                  event={(pageSize: any) => handlePageSizeChange(pageSize)}
                  pageSize={paginationInfo.page_size}
                />
                <div className='ms-4 text-muted'>
                  {intl.formatMessage(
                    {id: 'SHOWING_TOTAL_ROWS'},
                    {current: rows?.length, total: paginationInfo.total}
                  )}
                </div>
              </>
            )}
          </div>
          <div className='col-auto d-flex align-items-center justify-content-center justify-content-sm-end m-auto me-sm-0'>
            {paginationInfo && usePagination && (
              <PaginateWidget
                onPageChange={(page: number) => handlePageChange(page)}
                pagination={paginationInfo}
              />
            )}
          </div>
        </div>
        {/* end::Paginate */}
      </div>
      {/* end::Body */}
    </>
  )
}

export {Table}
