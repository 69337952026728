import {isEmpty} from 'lodash'
import React, {useCallback, useContext, useEffect, useState} from 'react'
import {MeterModel} from '../../app/modules/common'
import {round} from '../helpers'
import {useAuth} from '../providers'
import {ValidationErrorModal} from '../widgets'

export interface MeterContextModel {
  isMeterChange?: Boolean
  meter: MeterModel
  setMeterChange: (_isMeterChange: boolean) => void
}

const MeterContext = React.createContext<MeterContextModel>({
  meter: {balance: 0, recharge_amount: 0, recharge_threshold: 0, is_enabled: false, name: ''},
  setMeterChange: (_isMeterChange: boolean) => {},
})

const MeterProvider: React.FC = ({children}) => {
  const [isMeterChange, setMeterChange] = useState(true)
  const [meter, setMeter] = useState<MeterModel>({
    balance: 0,
    recharge_amount: 0,
    recharge_threshold: 0,
    is_enabled: false,
    name: '',
  })
  const {currentUser} = useAuth()
  const [validationErrors, setValidationErrors] = useState<any>()
  const value: MeterContextModel = {
    isMeterChange,
    meter,
    setMeterChange,
  }

  const loadMeter = useCallback(async () => {
    if (isEmpty(currentUser)) return
    // return await CommonService.getMeter(currentUser?.encrypt_key)
  }, [currentUser])

  useEffect(() => {
    if (isMeterChange && currentUser) {
      try {
        const res: any = loadMeter()
        setMeter((prev) => ({...prev, ...res, balance: round(Number(res.balance))}))
      } catch (error: any) {
        setValidationErrors(error?.response)
      } finally {
        setMeterChange(false)
      }
    }
  }, [currentUser, isMeterChange, loadMeter])

  return (
    <MeterContext.Provider value={value}>
      {children}
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
    </MeterContext.Provider>
  )
}

function useMeter() {
  return useContext(MeterContext)
}

export {MeterProvider, useMeter}
