export const PAGINATION_PAGE_NUMBER = 2
export const DEFAULT_PAGE = '1'
export const DEFAULT_PAGE_SIZE = '10'
export const PAGE_SIZE_MAX = '200'

export const FORMAT_DATE = {
  DATE_TIME: 'MM/DD/YYYY hh:mm A',
  DATE: 'MM/DD/YYYY',
}

export const VALID_COLOR = '#50cd89'
export const INVALID_COLOR = '#f1416c'
export const DEFAULT_COLOR = '#e4e6ef'

export const TABLE_KEY_COLUMN = {
  CAN_CHECK: 'canCheck',
  ACTIONS: 'actions',
  SUB_ACTIONS: 'sub-actions',
}
