import clsx from 'clsx'
import React from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../components'
import {Button} from '../widgets'

type Props = {
  show: boolean
  title?: string
  classNameDialog?: string
  classNameMessage?: string
  message: any
  messagePrimary: string
  messageSecondary?: string
  handlePrimaryAction: () => void
  handleSecondaryAction?: () => void
  loadingPrimary?: boolean
  loadingSecondary?: boolean
  handleClose: (status?: boolean) => void
}

const ConfirmActionModal: React.FC<Props> = ({
  show = false,
  title = '',
  classNameDialog = '',
  classNameMessage = '',
  message,
  messagePrimary = '',
  messageSecondary = '',
  handlePrimaryAction,
  handleSecondaryAction,
  loadingPrimary = false,
  loadingSecondary = false,
  handleClose,
}) => {
  return (
    <Modal
      id='gori_modal_confirm_action'
      tabIndex={-1}
      aria-hidden='true'
      centered
      dialogClassName={clsx('mw-600px h-auto', classNameDialog)}
      show={show}
      backdrop='static'
      onHide={() => {
        handleClose()
      }}
    >
      <div className='modal-content'>
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <KTSVG path='/media/gori/errors/error.svg' className='text-danger' small={false} />
            {message && <div className={clsx('mt-6 fs-5', {classNameMessage})}>{message}</div>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='row'>
            <div className='d-flex justify-content-end'>
              {messageSecondary && (
                <Button
                  className='btn btn-secondary me-3'
                  label={messageSecondary}
                  loadingText={messageSecondary}
                  loading={loadingSecondary}
                  event={handleSecondaryAction}
                />
              )}
              {messagePrimary && (
                <Button
                  className='btn btn-primary'
                  label={messagePrimary}
                  loadingText={messagePrimary}
                  loading={loadingPrimary}
                  event={handlePrimaryAction}
                />
              )}
            </div>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export {ConfirmActionModal}
