import React, {useContext, useState} from 'react'
import {A1BatchesProviderModel} from '../../app/modules/a1/core/models'

const initA1BatchContextPropsState = {
  a1Batch: null,
  setA1Batch: () => {},
  reloadTableDetail: false,
  setReloadTableDetail: () => {},
}

const A1BatchContext = React.createContext<A1BatchesProviderModel>(initA1BatchContextPropsState)

const A1BatchesProvider: React.FC = ({children}) => {
  const [a1Batch, setA1Batch] = useState<any>(initA1BatchContextPropsState.a1Batch)
  const [reloadTableDetail, setReloadTableDetail] = useState<boolean>(
    initA1BatchContextPropsState.reloadTableDetail
  )

  return (
    <A1BatchContext.Provider value={{a1Batch, setA1Batch, reloadTableDetail, setReloadTableDetail}}>
      {children}
    </A1BatchContext.Provider>
  )
}

function useA1BatchesProvider() {
  return useContext(A1BatchContext)
}

export {A1BatchesProvider, useA1BatchesProvider}
