import {FC, createContext, useContext, useEffect, useRef, useState} from 'react'
import {AuthModel, UserModel} from '../../app/modules/auth/core/models'
import {CommonService} from '../../app/modules/common'
import {SwapperComponent} from '../assets/ts/components'
import * as authHelper from '../helpers'
import {useCancelToken} from '../hooks'
import {useLanguage} from '../i18n/Metronici18n'
import {LayoutSplashScreen} from '../layout'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: (currentUser: UserModel | undefined) => void
  logout: () => void
  handleSaveCurrentUser: (currentUser: UserModel | undefined) => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  handleSaveCurrentUser: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC = ({children}) => {
  const {setLanguage} = useLanguage()
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>(undefined)

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const handleSaveCurrentUser = (currentUser: UserModel | undefined) => {
    if (!currentUser) return
    setLanguage(currentUser?.language)
    setCurrentUser(currentUser)
  }

  const logout = async () => {
    await SwapperComponent.removeInstances() // fix title header
    await saveAuth(undefined)
    await setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        handleSaveCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC = ({children}) => {
  const {auth, logout, handleSaveCurrentUser} = useAuth()
  const {newCancelToken, isCancel} = useCancelToken()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const config = {cancelToken: newCancelToken()}

    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const {data}: any = await CommonService.getUser(config)
          if (data) {
            handleSaveCurrentUser(data)
          }
        }
      } catch (error) {
        if (isCancel(error)) return
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.access_token) {
      requestUser()
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthInit, AuthProvider, useAuth}
