import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLayout, usePageData} from '../../../../layout'

const DefaultTitle = () => {
  const {pageTitle, pageActions, pageDescription, pageBreadcrumbs} = usePageData()
  const {config} = useLayout()
  return (
    <div
      id='gori-page-title'
      data-gori-swapper='true'
      data-gori-swapper-mode='prepend'
      data-gori-swapper-parent="{default: '#gori_content_container', xxl: '#gori_header_container'}"
      className='page-title d-flex align-items-start flex-wrap me-xxl-2 pb-5 pb-xxl-0'
    >
      {/* begin::Heading */}
      {pageTitle && (
        <>
          <h1 className='d-flex flex-column text-dark fw-bolder my-0 fs-1 pt-2'>{pageTitle}</h1>
          <div className='d-flex align-items-center flex-shrink-0'>{pageActions}</div>
          <div className='mt-1'>{pageDescription}</div>
        </>
      )}

      {/* end::Heading */}

      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs && (
          <ul className='breadcrumb breadcrumb-dot fw-bold fs-base my-1'>
            {Array.from(pageBreadcrumbs)
              .filter((b) => !b.isSeparator)
              .map((item, index) => (
                <li
                  className={clsx('breadcrumb-item', {
                    'text-dark': !item.isSeparator && item.isActive,
                    'text-muted': !item.isSeparator && !item.isActive,
                  })}
                  key={`${item.path}${index}`}
                >
                  <Link className='text-muted' to={item.path}>
                    {item.title}
                  </Link>
                </li>
              ))}
            <li className='breadcrumb-item text-dark'>{pageTitle}</li>
          </ul>
        )}
    </div>
  )
}

export {DefaultTitle}
