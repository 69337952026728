import clsx from 'clsx'
import React, {ChangeEvent, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {KTSVG} from '../../components'
import {fileHelper, formatBytes} from '../../helpers'
import {Button} from '../form/Button'
import {A1_CONSTANTS} from '../../../app/modules/a1'

type Props = {
  className?: any
  supportedFormat: any
  maxRow?: number
  size: {
    label: string
    value: number
  }
  onSubmit: any
  reloadForm?: boolean
}

const UploadMultipleFile: React.FC<Props> = ({
  className,
  supportedFormat,
  maxRow = 0,
  size,
  onSubmit,
  reloadForm = false,
}) => {
  const intl = useIntl()
  const [fileInput, setFileInput] = React.useState<any>([])

  const setFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return
    }
    const files = Array.from(e.target.files)
    e.target.value = '' // reset file upload

    if (files.length > 10) {
      return toast.warning(intl.formatMessage({id: 'UPLOAD_UP_TO_10_FILES'}))
    }
    for (const file of files) {
      if (!supportedFormat.includes(file.type)) {
        if (supportedFormat === A1_CONSTANTS.UPLOAD_CONFIG.T86_DOCUMENT_FORMAT) {
          return toast.error(intl.formatMessage({id: 'INVALID_FILE_TYPE_T86_ONLY'}))
        } else {
          return toast.error(intl.formatMessage({id: 'INVALID_FILE_TYPE_CSV_ONLY'}))
        }
      }
      if (maxRow) {
        const {row} = await fileHelper.checkRowHaveData(file)
        if (row > maxRow) {
          return toast.error(
            intl.formatMessage({id: 'CANNOT_UPLOAD_MORE_THAN_INPUT_ITEMS'}, {input: maxRow})
          )
        }
      }
      if (file.size > size.value) {
        return toast.warning(
          intl.formatMessage(
            {id: 'FILE_INPUT_ERROR_UPLOADING_PLEASE_UPLOAD_FILES_SMALLER_THAN_INPUT'},
            {fileName: file.name, size: size.label}
          )
        )
      }
    }
    setFileInput(files)
    onSubmit({files: files})
    e.target.value = ''
  }

  const handleDelete = (index) => {
    fileInput.splice(index, 1)
    setFileInput(fileInput)
    onSubmit({files: fileInput})
  }

  useEffect(() => {
    if (reloadForm) {
      setFileInput([])
    }
  }, [reloadForm])

  return (
    <>
      <div className={clsx('dropzone', className)}>
        <div className='d-flex justify-content-center my-4'>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div className='fs-4 fw-bold text-dark mb-1'>
              {intl.formatMessage({id: 'DRAG_AND_DROP_HERE_OR'})}
              <span className='ms-2 text-primary'>{intl.formatMessage({id: 'BROWSE_FILES'})}</span>
            </div>
            <Button
              className='btn btn-secondary mt-4'
              label={intl.formatMessage({id: 'UPLOAD_FILE'})}
            />
          </div>
          <input
            type='file'
            id='file-upload'
            name='file-img'
            onChange={(e: any) => setFile(e)}
            className='dropzone__upload mt-n10 cursor-pointer'
            multiple
            accept={supportedFormat.join(',')}
          />
        </div>
      </div>
      {fileInput.map((item: any, index) => {
        return (
          <div className='d-flex align-items-center mt-2 col-6'>
            <div className='flex-fill d-flex justify-content-between bg-light-primary p-3 rounded-2 fw-bold w-100'>
              <span className='text-truncate'>{item?.name}</span>
              <span className='text-nowrap'>{formatBytes(item?.size)}</span>
            </div>
            <div
              className='text-hover-danger cursor-pointer ms-4'
              onClick={() => handleDelete(index)}
            >
              <KTSVG path='/media/gori/common/empty.svg' small={false} />
            </div>
          </div>
        )
      })}
    </>
  )
}

export {UploadMultipleFile}
