import {useFormik} from 'formik'
import {useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {useCancelToken, UseYupValidate} from '../../../../../gori/hooks'
import {Button, InputTextFormik, ValidationErrorModal} from '../../../../../gori/widgets'
import {A1BatchService} from '../../../a1'

type Props = {
  nameHeader: string
  show: boolean
  handleClose?: any
  handleUpdateFilter?: any
  dataFilters: any
  optionsCurrentFilter: any
  filterKey: 'a1_batch_filter' | 'order_filter'
}

const SaveFilterModal: React.FC<Props> = ({
  nameHeader,
  show,
  handleClose,
  handleUpdateFilter,
  dataFilters,
  optionsCurrentFilter = [],
  filterKey,
}) => {
  const intl = useIntl()
  const {stringYup} = UseYupValidate()
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [loading, setLoading] = useState<{create: boolean}>({
    create: false,
  })

  const initValidateSchema = useMemo(() => {
    return {
      options: Yup.array(),
      name: stringYup(255, 'NAME').test(
        'checkNameRequired',
        intl.formatMessage(
          {id: 'INPUT_ALREADY_EXISTS'},
          {input: intl.formatMessage({id: 'SAVED_FILTER_NAME'})}
        ),
        function (value) {
          return this.parent.options?.indexOf(value?.trim()) === -1
        }
      ),
    }
  }, [intl, stringYup])

  const formik = useFormik({
    initialValues: {
      options: optionsCurrentFilter.map((item) => item.label.trim()),
      name: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape(initValidateSchema),
    onSubmit: async (values) => {
      let payload = {
        name: values.name,
        key: filterKey,
        payload: {
          ...dataFilters,
        },
      }

      try {
        setLoading((prev) => ({...prev, create: true}))
        const {message}: any = await A1BatchService.createDataSaveFilter(payload, {
          cancelToken: newCancelToken(),
        })
        await handleCloseModal()
        await handleUpdateFilter()
        await toast.success(message)
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, create: false}))
      }
    },
  })

  const handleCloseModal = () => {
    handleClose()
    formik.resetForm()
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal show={show} onHide={handleCloseModal} centered>
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title>{nameHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputTextFormik
              labelClassName='form-label fw-bolder text-dark fs-6'
              label={intl.formatMessage({id: 'NAME'})}
              size='lg'
              formik={formik}
              name='name'
              required
            />
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
                disabled={loading.create}
              />
              <Button
                className={'btn btn-primary'}
                label={intl.formatMessage({id: 'SAVE'})}
                event={formik.handleSubmit}
                loading={loading.create}
                disabled={loading.create}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {SaveFilterModal}
