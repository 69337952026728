import ReactDOM from 'react-dom'
import {QueryClient, QueryClientProvider} from 'react-query'
import 'react-toastify/dist/ReactToastify.css'
import './../node_modules/bootstrap-daterangepicker/daterangepicker.css'
import './../node_modules/bootstrap/dist/css/bootstrap.css'
import './gori/assets/sass/plugins.scss'
import './gori/assets/sass/style.react.scss'
import './gori/assets/sass/style.scss'
import {MetronicI18nProvider} from './gori/i18n/Metronici18n'
import {AuthProvider} from './gori/providers'
import {AppRoutes} from './app/routing/AppRoutes'

const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <MetronicI18nProvider>
        <AppRoutes />
      </MetronicI18nProvider>
    </AuthProvider>
  </QueryClientProvider>,
  document.getElementById('root')
)
