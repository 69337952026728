import {useFormik} from 'formik'
import React, {useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {KTSVG} from '../../../../../gori/components'
import {useA1BatchesProvider} from '../../../../../gori/providers'
import {Button, UploadFile, ValidationErrorModal} from '../../../../../gori/widgets'
import {A1BatchService, A1_CONSTANTS} from '../../../a1'
import {useCancelToken, UseYupValidate} from '../../../../../gori/hooks'

type Props = {
  show: boolean
  handleClose: () => void
  handleSuccess: () => void
}

const UploadFileModal: React.FC<Props> = ({show, handleClose, handleSuccess}) => {
  const intl = useIntl()
  const {stringYup} = UseYupValidate()
  const {a1Batch} = useA1BatchesProvider()
  const {isCancel, newCancelToken} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [loading, setLoading] = useState<{update: boolean; download: boolean}>({
    update: false,
    download: false,
  })

  const handleCloseModal = () => {
    handleClose()
  }

  const initValidateSchema = useMemo(() => {
    return {
      file: stringYup(255, 'FILE'),
    }
  }, [stringYup])

  const formik = useFormik({
    initialValues: {
      file: null,
    },
    validationSchema: Yup.object().shape(initValidateSchema),
    onSubmit: async (values) => {
      try {
        setLoading((prev) => ({...prev, update: true}))
        const config = {cancelToken: newCancelToken()}
        const payload = new FormData()
        if (values?.file) {
          payload.append('file', values.file)
        }
        const res: any = await A1BatchService.uploadCSV(a1Batch.id, payload, config)
        if (res) {
          toast.success(res.message)
          handleSuccess()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, update: false}))
      }
    },
  })

  const handleDownloadTemplate = async () => {
    try {
      setLoading((prev) => ({...prev, download: true}))
      const response: any = await A1BatchService.downloadOrdersA1Batch(a1Batch.id, {
        cancelToken: newCancelToken(),
      })
      if (response) {
        const blob = new Blob([response.content], {type: response.type})
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = response.file_name
        link.click()
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setLoading((prev) => ({...prev, download: false}))
    }
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}

      <Modal
        id='gori_modal_upload_file_modal'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1250px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'JOURNEY_INFORMATION'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <Container>
              <div className='mt-10'>
                <div className='d-flex align-items-center justify-content-between'>
                  <h3 className='required'>{intl.formatMessage({id: 'UPLOAD_FILE'})}</h3>
                  <Button
                    event={handleDownloadTemplate}
                    className='btn btn-sm btn-secondary fw-bolder'
                    label={intl.formatMessage({id: 'EXPORT_AS_CSV'})}
                    children={<KTSVG path='/media/gori/a1/fileDownload.svg' />}
                    loading={loading.download}
                  />
                </div>
                <UploadFile
                  className='bg-light-primary border-primary border-2 mt-5'
                  onSubmit={(e) => formik.setFieldValue('file', e.file)}
                  supportedFormat={A1_CONSTANTS.UPLOAD_CONFIG.SUPPORTED_FORMATS}
                  maxRow={A1_CONSTANTS.UPLOAD_CONFIG.MAX_UPLOAD}
                  size={{label: '8MB', value: A1_CONSTANTS.UPLOAD_CONFIG.SIZE}}
                />
                {formik.errors.file && formik.touched.file && (
                  <div className='text-danger mt-2'>{formik.errors.file}</div>
                )}
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'UPDATE'})}
                disabled={loading.update}
                event={formik.handleSubmit}
                loading={loading.update}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {UploadFileModal}
