import {FC} from 'react'
import {useIntl} from 'react-intl'

const Error404: FC = () => {
  const intl = useIntl()

  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-30'>
        {intl.formatMessage({id: 'PAGE_NOT_FOUND'})}
      </h1>
    </>
  )
}

export {Error404}
