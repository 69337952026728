import clsx from 'clsx'
import {compact, find, isEmpty} from 'lodash'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {A1Helpers, MassUpdateModal} from '../../../a1'
import {ConfirmActionModal, TableSkeleton} from '../../../../../gori/components'
import {
  FORMAT_DATE,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  OPTION_COUNTRIES,
  TABLE_KEY_COLUMN,
} from '../../../../../gori/constants'
import {convertUserTimeZone} from '../../../../../gori/helpers'
import {useA1BatchesProvider, useAuth, useDisabled} from '../../../../../gori/providers'
import {
  Button,
  SelectFormik,
  Table,
  TableBody,
  TableWrapper,
  ValidationErrorModal,
} from '../../../../../gori/widgets'
import {A1BatchService, A1_CONSTANTS} from '../../../a1'
import {useCancelToken, UsePermission} from '../../../../../gori/hooks'

type Props = {
  show: boolean
  handleClose: () => void
}

const HoldOrderModal: React.FC<Props> = ({show, handleClose}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {a1Batch} = useA1BatchesProvider()
  const {isOrigin, isDestination} = UsePermission()
  const {setPageDisabled} = useDisabled()
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [tableData, setTableData] = useState<any>([])
  const [loading, setLoading] = useState<{first: boolean; getOrder: boolean; release: boolean}>({
    first: true,
    getOrder: false,
    release: false,
  })
  const pageCurrent = useRef(Number(DEFAULT_PAGE))
  const [pagination, setPagination] = useState<{
    page: number
    page_size: number
    total: number
    total_pages: number
  }>({
    page: 0,
    page_size: 0,
    total: 0,
    total_pages: Number(DEFAULT_PAGE),
  })
  const [hasLoadMore, setHasLoadMore] = useState<boolean>(false)
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [selectedList, setSelectedList] = useState<any>([])
  const [selectedMaster, setSelectedMaster] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<{
    confirm: boolean
    massUpdate: boolean
  }>({
    confirm: false,
    massUpdate: false,
  })
  const [dataModal, setDataModal] = useState<{
    massUpdate: {
      selectAll: boolean
      selectedList: any
      type: string
    } | null
    orderId: number | null
  }>({
    massUpdate: null,
    orderId: null,
  })

  const handleCloseModal = () => {
    handleClose()
  }

  const showMassUpdate = useMemo(() => {
    return A1Helpers.checkJourneyStatus({
      statusCurrent: a1Batch?.journey_status,
      statusCheck: A1_CONSTANTS.OPTIONS_JOURNEY_STATUS.CUSTOMS.value[0],
      comparisonType: 'less_than_or_equal',
    })
  }, [a1Batch?.journey_status])

  const configParams = useMemo(
    () => ({
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      first_held: 1,
    }),
    []
  )

  const setTable = useCallback(async () => {
    try {
      setLoading((prev) => ({...prev, first: true}))
      const config = {
        params: configParams,
        cancelToken: newCancelToken(),
      }
      const response: any = await A1BatchService.getA1BatchOrders(a1Batch?.id, config)
      if (response) {
        const newOrder = Array.from(response.orders, (order: any) => {
          return {
            ...order,
            canCheck: true,
          }
        })
        setTableData(newOrder)
        setPagination(response.pagination)
        setHasLoadMore(response.pagination.total_pages > 1)
      }
    } catch (error: any) {
      if (isCancel(error)) return
    } finally {
      setLoading((prev) => ({...prev, first: false}))
      setPageDisabled(false)
    }
  }, [a1Batch?.id, configParams, isCancel, newCancelToken, setPageDisabled])

  const fetchMore = async () => {
    if (pageCurrent.current < pagination?.total_pages) {
      pageCurrent.current++

      try {
        setLoading((prev) => ({...prev, getOrder: true}))
        const config = {
          params: {...configParams, page: pageCurrent.current},
          cancelToken: newCancelToken(),
        }
        const response: any = await A1BatchService.getA1BatchOrders(a1Batch.id, config)
        if (response) {
          let newOrder = Array.from(response.orders, (order: any) => {
            return {
              ...order,
              canCheck: true,
            }
          })
          setTableData((prev) => [...prev, ...newOrder])
        }
      } catch (error: any) {
        if (isCancel(error)) return
      } finally {
        setLoading((prev) => ({...prev, getOrder: false}))
      }
    } else {
      setHasLoadMore(false)
    }
  }

  useEffect(() => {
    setTable()
  }, [setTable])

  const columns = useMemo(() => {
    const _columns = [
      {
        id: 'created_at',
        Header: intl.formatMessage({id: 'DATE'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          return (
            row.original?.created_at &&
            convertUserTimeZone(row.original?.created_at, currentUser, FORMAT_DATE.DATE)
          )
        },
      },
      {
        id: 'code',
        Header: 'BAE ID',
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => (
          <div
            className={clsx(
              'text-gray-800 mb-1 cursor-pointer d-inline cursor-pointer text-hover-primary'
            )}
          >
            {row.original.bae_id}
          </div>
        ),
      },
      {
        id: 'content',
        Header: intl.formatMessage({id: 'CONTENT'}),
        accessor: 'content',
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) =>
          !isEmpty(row.original?.order_items) && (
            <div>
              {row.original?.order_items?.map((item: any, idx) => {
                const content = find(A1_CONSTANTS.OPTIONS_CONTENT_TYPE, {value: item?.a1_content})
                return (
                  content && (
                    <div className='d-flex' key={idx}>
                      <span className='mw-200px text-truncate'>
                        {intl.formatMessage({id: content?.label})}
                      </span>
                    </div>
                  )
                )
              })}
            </div>
          ),
      },
      {
        id: 'description',
        Header: intl.formatMessage({id: 'DESCRIPTION'}),
        accessor: 'description',
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) =>
          !isEmpty(row.original?.order_items) && (
            <div>
              {row.original?.order_items?.map((item: any, idx) => {
                return (
                  <div className='d-flex' key={idx}>
                    <span className='mw-200px text-truncate'>{item?.a1_description}</span>
                  </div>
                )
              })}
            </div>
          ),
      },
      {
        id: 'ship_to',
        Header: intl.formatMessage({id: 'SHIP_TO'}),
        headerClassName: 'min-w-200px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          const {to_street1, to_street2, to_city, to_state, to_zip} = row.original.order_ext
          const addressTo = compact([
            to_street1,
            to_street2,
            to_city,
            to_state && to_zip ? `${to_state} ${to_zip}` : to_state || to_zip,
          ]).join(', ')
          return <span className='fw-bolder text-dark'>{addressTo}</span>
        },
      },
      {
        id: 'hts_code',
        Header: intl.formatMessage({id: 'HTS_CODE'}),
        accessor: 'hts_code',
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) =>
          !isEmpty(row.original?.order_items) && (
            <div>
              {row.original?.order_items?.map((item: any, idx) => {
                return (
                  <div className='d-flex' key={idx}>
                    <span className='mw-200px text-truncate'>{item?.a1_hs_tariff_number}</span>
                  </div>
                )
              })}
            </div>
          ),
      },
      {
        id: 'container_id',
        Header: intl.formatMessage({id: 'CONTAINER_ID'}),
        accessor: 'container_id',
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          return row?.original?.container_id || null
        },
      },
      {
        id: 'country',
        Header: intl.formatMessage({id: 'COUNTRY_OF_ORIGIN'}),
        accessor: 'country',
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) =>
          !isEmpty(row.original?.order_items) && (
            <div>
              {row.original?.order_items?.map((item: any, idx) => {
                const country = find(OPTION_COUNTRIES, {value: item?.a1_country})
                return (
                  country && (
                    <div className='d-flex' key={idx}>
                      <span className='mw-200px text-truncate'>{country?.label}</span>
                    </div>
                  )
                )
              })}
            </div>
          ),
      },
      {
        id: 'quantity',
        Header: intl.formatMessage({id: 'QUANTITY'}),
        accessor: 'quantity',
        headerClassName: 'min-w-150px text-center',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) =>
          !isEmpty(row.original?.order_items) && (
            <div className='d-flex flex-column align-items-center'>
              {row.original?.order_items?.map((item: any, idx) => {
                return (
                  <div className='d-flex' key={idx}>
                    <span className='mw-200px text-truncate'>{item?.a1_quantity}</span>
                  </div>
                )
              })}
            </div>
          ),
      },
      {
        id: 'value',
        Header: intl.formatMessage({id: 'VALUES_$'}),
        accessor: 'value',
        headerClassName: 'min-w-150px text-center',
        cellClassName: 'text-center',
        Cell: ({row}: {row: any}) =>
          !isEmpty(row.original?.order_items) && (
            <div className='d-flex flex-column align-items-center'>
              {row.original?.order_items?.map((item: any, idx) => {
                return (
                  <div className='d-flex' key={idx}>
                    <span className='mw-200px text-truncate'>{item?.a1_value}</span>
                  </div>
                )
              })}
            </div>
          ),
      },
      {
        id: 'tracking_number',
        Header: intl.formatMessage({id: 'TRACKING_NUMBER'}),
        accessor: 'tracking_number',
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          return row?.original?.tracking_number || null
        },
      },
      {
        id: 'hawb',
        Header: 'HAWB',
        accessor: 'content',
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          return row?.original?.hawb || null
        },
      },
      {
        id: 'release_status',
        Header: intl.formatMessage({id: 'RELEASE_STATUS'}),
        headerClassName: 'min-w-125px fixed-action right-n1',
        cellClassName: clsx('text-wrap fixed-action right-n1', {
          'cursor-no-drop': isOrigin,
        }),
        Cell: ({row}: {row: any}) => {
          if (
            isOrigin ||
            row.original.release_status === A1_CONSTANTS.OPTIONS_RELEASE_STATUS.RELEASED.value ||
            !showMassUpdate
          ) {
            const foundRelease =
              find(Object.values(A1_CONSTANTS.OPTIONS_RELEASE_STATUS), {
                value: row?.original?.release_status,
              }) || null
            return foundRelease && intl.formatMessage({id: foundRelease?.label})
          } else {
            return (
              <Button
                className='btn btn-primary btn-sm'
                label={intl.formatMessage({id: 'RELEASE'})}
                event={() => {
                  setDataModal((prev) => ({...prev, orderId: row.original.id}))
                  setShowModal((prev) => ({...prev, confirm: true}))
                }}
              />
            )
          }
        },
      },
    ]

    if (isDestination) {
      const canCheck: any = {
        id: TABLE_KEY_COLUMN.CAN_CHECK,
        Header: intl.formatMessage({id: 'ID'}),
        accessor: 'canCheck',
      }
      _columns.splice(1, 0, canCheck)
    }
    return _columns.filter((col) => col)
  }, [intl, isOrigin, isDestination, currentUser, showMassUpdate])

  const handleUpdateReleaseStatus = useCallback(async () => {
    try {
      setLoading((prev) => ({...prev, release: true}))
      const config = {cancelToken: newCancelToken()}
      const payload = {
        order_ids: [dataModal.orderId],
        batch_id: a1Batch?.id,
        release_status: A1_CONSTANTS.OPTIONS_RELEASE_STATUS.RELEASED.value,
        all: false,
      }
      const {message}: any = await A1BatchService.releasedOrder(payload, config)
      if (message) {
        setTable()
        toast.success(message)
        setShowModal((prev) => ({...prev, confirm: false}))
        setDataModal((prev) => ({...prev, orderId: null}))
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setLoading((prev) => ({...prev, release: false}))
    }
  }, [newCancelToken, dataModal.orderId, a1Batch?.id, setTable, isCancel])

  const handleMassUpdate = (option) => {
    setShowModal((prev) => ({...prev, massUpdate: true}))
    setDataModal((prev) => ({
      ...prev,
      massUpdate: {
        selectAll: selectedList.length < pagination?.total ? selectAll : false,
        selectedList: {
          selected: selectedList,
          total: pagination?.total,
        },
        type: option.value,
      },
    }))
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      {showModal.confirm && (
        <ConfirmActionModal
          show={showModal.confirm}
          title={intl.formatMessage({id: 'RELEASE_ORDER'})}
          message={
            <span
              dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'RELEASE_ORDER_CONFIRM'})}}
            />
          }
          loadingPrimary={loading.release}
          messagePrimary={intl.formatMessage({id: 'CONFIRM'})}
          messageSecondary={intl.formatMessage({id: 'CANCEL'})}
          handlePrimaryAction={handleUpdateReleaseStatus}
          handleSecondaryAction={() => setShowModal((prev) => ({...prev, confirm: false}))}
          handleClose={() => setShowModal((prev) => ({...prev, confirm: false}))}
        />
      )}
      {showModal.massUpdate && (
        <MassUpdateModal
          show={showModal.massUpdate}
          handleClose={() => setShowModal((prev) => ({...prev, massUpdate: false}))}
          data={dataModal.massUpdate}
          reloadTable={() => {
            setTable()
          }}
        />
      )}
      <Modal
        id='gori_modal_upload_file_modal'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1250px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'RELEASE_STATUS'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <TableWrapper>
              {loading.first ? (
                <TableSkeleton countRow={8} />
              ) : (
                <>
                  <InfiniteScroll
                    dataLength={tableData}
                    next={fetchMore}
                    hasMore={hasLoadMore}
                    height='50vh'
                    loader={
                      loading.getOrder && (
                        <div className='d-flex justify-content-center'>
                          <span className='spinner-border' />
                        </div>
                      )
                    }
                    endMessage={
                      <p className='d-flex justify-content-center'>
                        {intl.formatMessage({id: 'NO_MORE_ITEMS'})}
                      </p>
                    }
                  >
                    <div className='card'>
                      {selectedList.length > 0 && (
                        <div className='d-flex align-items-center flex-wrap gap-3 card-body pt-0'>
                          <div className='fw-bolder me-5'>
                            <span className='me-2'>
                              {selectAll ? pagination.total : selectedList.length}
                            </span>
                            {intl.formatMessage({id: 'SELECTED'})}
                          </div>
                          {selectedMaster && selectedList.length < pagination?.total && (
                            <div
                              onClick={() => setSelectAll(!selectAll)}
                              className='fw-bolder me-5 link-blue text-decoration-underline cursor-pointer'
                            >
                              {selectAll
                                ? intl.formatMessage({id: 'CLEAR_SELECTION'})
                                : intl.formatMessage({id: 'SELECT_ALL'}) + ` ${pagination.total}`}
                            </div>
                          )}
                          {showMassUpdate && (
                            <SelectFormik
                              className='w-200px fw-bold fs-5'
                              value={{label: intl.formatMessage({id: 'MASS_UPDATE'}), value: ''}}
                              options={Object.values(A1_CONSTANTS.MASS_UPDATE)}
                              onChange={handleMassUpdate}
                              hasUseIntl={true}
                              emptyDefault={false}
                            />
                          )}
                        </div>
                      )}
                    </div>
                    <TableBody>
                      <Table
                        columns={columns}
                        data={tableData}
                        tbodyClass='text-gray-600 fw-bold'
                        useCheckBox
                        usePagination={false}
                        callBackSetData={(selected) => {
                          setSelectedMaster(selected?.length === tableData?.length)
                          setSelectedList(selected)
                        }}
                      />
                    </TableBody>
                  </InfiniteScroll>
                </>
              )}
            </TableWrapper>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {HoldOrderModal}
