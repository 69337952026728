import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {SwapperComponent} from '../../../assets/ts/components'
import {useAuth} from '../../../providers'

const Topbar = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  // const {meter} = useMeter()
  // const navigate = useNavigate()
  // const {routes} = UseRoutesPermission()

  return (
    <div className='d-flex align-items-center flex-shrink-0'>
      {/* {currentUser && (
        <>
          <div>
            <button
              type='button'
              className='btn d-flex align-items-center flex-column flex-sm-row btn-outline btn-sm fs-3 fw-boldest py-1 balance-button'
              onClick={() => routes.DEPOSITS.hasPermission && navigate('/deposits')}
            >
              <span className={clsx('balance-company text-nowrap rounded-pill')}>
                {currentUser?.name}
              </span>
              <div>
                <div>
                  $
                  <CountUp
                    start={0}
                    end={meter?.balance}
                    separator=','
                    decimal={'.'}
                    decimals={2}
                    className='me-2'
                  />
                  <KTSVG path='/media/gori/topbar/wallet.svg' className='me-2 svg-icon-2' />
                </div>
              </div>
            </button>
          </div>
        </>
      )} */}

      {!currentUser && (
        <Link
          to='/auth/login'
          onClick={() => SwapperComponent.removeInstances()}
          className='d-flex flex-stack'
        >
          <button
            type='button'
            className='btn bg-white btn-outline text-primary btn-active-light-primary rounded-pill px-5 px-md-8'
          >
            {intl.formatMessage({id: 'LOG_IN'})}
          </button>
        </Link>
      )}
    </div>
  )
}

export {Topbar}
