import CryptoJS from 'crypto-js'
import {StorageHelpers} from '.'
import {AuthModel} from '../../app/modules/auth/core/models'

const AUTH_STORAGE_KEY = process.env.REACT_APP_AUTH_LOCAL_STORAGE_KEY || 'AUTH_STORAGE_KEY'
const SIGNATURE_ACCESS_KEY: any = process.env.REACT_APP_SIGNATURE_ACCESS_KEY

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = StorageHelpers.getItemLocalStorage(AUTH_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    var bytes = CryptoJS.AES.decrypt(lsValue, SIGNATURE_ACCESS_KEY)
    var originalText = bytes.toString(CryptoJS.enc.Utf8)
    const auth: AuthModel = JSON.parse(originalText) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = CryptoJS.AES.encrypt(JSON.stringify(auth), SIGNATURE_ACCESS_KEY).toString()
    StorageHelpers.setItemLocalStorage(AUTH_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    StorageHelpers.storageClear()
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export {getAuth, removeAuth, setAuth}
