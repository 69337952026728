export const COMMONS = {
  SHIPPING: {
    COMPANY: {label: 'COMPANY', value: 'company', required: true},
    FIRST_NAME: {label: 'FIRST_NAME', value: 'first_name', required: true},
    LAST_NAME: {label: 'LAST_NAME', value: 'last_name'},
    EMAIL: {label: 'EMAIL', value: 'email'},
    PHONE: {label: 'PHONE', value: 'phone'},
    ADDRESS: {label: 'ADDRESS', value: 'street1', required: true},
    ADDRESS_2: {label: 'ADDRESS_2', value: 'street2'},
    CITY: {label: 'CITY', value: 'city', required: true},
    STATE: {label: 'STATE', value: 'state', required: true},
    COUNTRY: {label: 'COUNTRY', value: 'country'},
    ZIP_CODE: {label: 'POSTAL_CODE', value: 'zip', required: true},
    RESIDENTIAL: {label: 'RESIDENTIAL', value: 'is_residential'},
    VERIFIED: {label: 'VERIFIED', value: 'is_verified'},
    SAVE_ADDRESS: {label: 'SAVE_ADDRESS', value: 'type'},
  },
  VALIDATE_ADDRESS: ['city', 'country', 'state', 'street1', 'street2', 'zip'],
}
