import {find, isEmpty} from 'lodash'
import moment from 'moment'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {KTSVG, TableSkeleton} from '../../../../../gori/components'
import {
  FORMAT_DATE,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  PERMISSION,
} from '../../../../../gori/constants'
import {convertUserTimeZone, setLoadingActionTable} from '../../../../../gori/helpers'
import {useCancelToken} from '../../../../../gori/hooks'
import {PageTitle} from '../../../../../gori/layout'
import {useAuth, useDisabled} from '../../../../../gori/providers'
import {
  Button,
  Table,
  TableBody,
  TableHeader,
  TableTabs,
  TableWrapper,
} from '../../../../../gori/widgets'
import {A1_CONSTANTS, A1BatchesFilter, A1BatchService} from '../../../a1'

const A1BatchesPage: React.FC = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {newCancelToken, isCancel} = useCancelToken()
  const [searchParams] = useSearchParams()
  const {setPageDisabled} = useDisabled()
  const location = useLocation()
  const navigate = useNavigate()
  const [show] = useState<{hasNotice: boolean}>({
    hasNotice: false,
  })
  const [loadingFirst, setLoadingFirst] = useState<Boolean>(true)
  const [statistic, setStatistic] = useState<any>([])
  const [tableData, setTableData] = useState<any>([])
  const [pagination, setPagination] = useState<any>({})

  const handleExport = useCallback(
    async (id) => {
      try {
        setTableData(setLoadingActionTable(tableData, [id], true, 'download'))
        const response: any = await A1BatchService.downloadOrdersA1Batch(id, {
          cancelToken: newCancelToken(),
        })
        if (response) {
          const blob = new Blob([response.content], {type: response.type})
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = response.file_name
          link.click()
        }
      } catch (error: any) {
        if (isCancel(error)) return
      } finally {
        setTableData(setLoadingActionTable(tableData, [id], false, 'download'))
      }
    },
    [isCancel, newCancelToken, tableData]
  )

  const columns = useMemo(
    () => [
      {
        id: 'created_at',
        Header: intl.formatMessage({id: 'DATE'}),
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) =>
          row.original.date && (
            <span className='fw-bold'>
              {convertUserTimeZone(row.original.date, currentUser, FORMAT_DATE.DATE)}
            </span>
          ),
      },
      {
        id: 'id',
        Header: intl.formatMessage({id: 'A1_BATCH_ID'}),
        accessor: 'code',
        headerClassName: 'min-w-150px',
        cellClassName: 'fw-bolder fs-6 border-0',
        Cell: ({row}: {row: any}) => <span className='fw-bold'>{row.original.code}</span>,
      },
      {
        id: 'name',
        Header: intl.formatMessage({id: 'A1_BATCH_NAME'}),
        headerClassName: 'min-w-150px',
        cellClassName: 'fw-bolder fs-6 border-0',
        Cell: ({row}: {row: any}) => (
          <div
            onClick={() =>
              navigate(`/a1-batches/${row.original.id}`, {
                state: {search: location.search},
              })
            }
            className='text-hover-primary text-dark cursor-pointer'
          >
            {row.original.name}
          </div>
        ),
      },
      {
        id: 'journey_status',
        Header: intl.formatMessage({id: 'STATUS'}),
        accessor: 'journey_status',
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) => {
          const journeyStatus = find(Object.values(A1_CONSTANTS.OPTIONS_JOURNEY_STATUS), {
            value: [row.original.journey_status],
          })
          return (
            !isEmpty(journeyStatus) && (
              <span className='d-flex align-items-center'>
                <KTSVG
                  path='/media/gori/a1/circle.svg'
                  className='svg-icon-8 me-2'
                  style={{color: journeyStatus?.color}}
                />
                <span className='fs-5 fw-bold'>
                  {intl.formatMessage({id: journeyStatus?.label})}{' '}
                </span>
              </span>
            )
          )
        },
      },
      {
        id: 'origin',
        Header: intl.formatMessage({id: 'ORIGIN'}),
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) => {
          const origin = row.original.journey_flight_infos.find(
            (item) => item.type === PERMISSION.ORIGIN
          )
          return (
            <span className='d-flex align-items-center'>
              <span className='fs-5 fw-bold text-gray-700'>{origin?.airport}</span>
            </span>
          )
        },
      },
      {
        id: 'destination',
        Header: intl.formatMessage({id: 'DESTINATION'}),
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) => {
          const destination = row.original.journey_flight_infos.find(
            (item) => item.type === PERMISSION.DESTINATION
          )
          return (
            <span className='d-flex align-items-center'>
              <span className='fs-5 fw-bold text-gray-700'>{destination?.airport}</span>
            </span>
          )
        },
      },
      {
        id: 'shipments',
        Header: intl.formatMessage({id: 'SHIPMENTS'}),
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) => (
          <span className='d-flex align-items-center'>
            <span className='fs-5 fw-bold text-gray-700'>{row.original?.order_total}</span>
          </span>
        ),
      },
      {
        id: 'pick-up',
        Header: intl.formatMessage({id: 'PICK_UP'}),
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) => {
          const datePickup = row.original?.apu_at || row.original?.epu_at
          return (
            datePickup && (
              <span className='d-flex align-items-center'>
                <span className='fs-5 fw-bold text-gray-700'>
                  {convertUserTimeZone(datePickup, currentUser, FORMAT_DATE.DATE)}
                </span>
              </span>
            )
          )
        },
      },
      {
        id: 'etd',
        Header: intl.formatMessage({id: 'ETD'}),
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) => {
          const origin = row.original.journey_flight_infos.find(
            (item) => item.type === PERMISSION.ORIGIN
          )
          return (
            <span className='d-flex align-items-center'>
              <span className='fs-5 fw-bold text-gray-700'>
                {origin?.etd && convertUserTimeZone(origin?.etd, currentUser, FORMAT_DATE.DATE)}
              </span>
            </span>
          )
        },
      },
      {
        id: 'eta',
        Header: intl.formatMessage({id: 'ETA'}),
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) => {
          const destination = row.original.journey_flight_infos.find(
            (item) => item.type === PERMISSION.DESTINATION
          )
          return (
            <span className='d-flex align-items-center'>
              <span className='fs-5 fw-bold text-gray-700'>
                {destination?.eta &&
                  convertUserTimeZone(destination?.eta, currentUser, FORMAT_DATE.DATE)}
              </span>
            </span>
          )
        },
      },
      {
        id: 'mawb',
        Header: 'MAWB',
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) => (
          <span className='d-flex align-items-center'>
            <span className='fs-5 fw-bold text-gray-700'>{row.original.mawb}</span>
          </span>
        ),
      },
      {
        id: 'actions-header',
        Header: intl.formatMessage({id: 'ACTIONS'}),
        headerClassName: 'w-150px text-center fixed-column',
        cellClassName: 'd-flex justify-content-center fixed-column',
        Cell: ({row}: {row: any}) => (
          <Button
            className='btn btn-sm btn-primary min-w-100px'
            label={intl.formatMessage({id: 'DOWNLOAD'})}
            loading={row.original.download}
            event={() => handleExport(row.original.id)}
          />
        ),
      },
    ],
    [currentUser, handleExport, intl, location.search, navigate]
  )

  const initFilterValue = useMemo(
    () => ({
      start: moment().subtract(29, 'days').startOf('day').format(),
      end: moment().endOf('day').format(),
    }),
    []
  )

  const setTable = useCallback(async () => {
    setPageDisabled(true)
    const config = {
      params: {
        page: searchParams.get('page') || DEFAULT_PAGE,
        page_size: searchParams.get('page_size') || DEFAULT_PAGE_SIZE,
        search_text: searchParams.get('search_text') || null,
        sort_by: searchParams.get('sort_by') || null,
        sort_direction: searchParams.get('sort_direction') || null,
        status: searchParams.get('status') || null,
        // BEGIN: Order filters
        date_from: searchParams.get('date_from') || initFilterValue.start,
        date_to: searchParams.get('date_to') || initFilterValue.end,
        code: searchParams.get('code') || null,
        name: searchParams.get('name') || null,
        origin_airport: searchParams.get('origin_airport') || null,
        destination_airport: searchParams.get('destination_airport') || null,
        journey_status: searchParams.get('journey_status') || null,
        // END: Order filters
      },
      cancelToken: newCancelToken(),
    }
    try {
      const response: any = await A1BatchService.getList(config)
      setTableData(response.batches)
      setPagination(response.pagination)
      setStatistic(response.statistics)
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setPageDisabled(false)
      setLoadingFirst(false)
    }
  }, [
    initFilterValue.end,
    initFilterValue.start,
    isCancel,
    newCancelToken,
    searchParams,
    setPageDisabled,
  ])

  useEffect(() => {
    setTable()
  }, [setTable])

  return (
    <>
      <PageTitle>
        <span className='d-flex align-items-center'>
          {intl.formatMessage({id: 'A1_BATCHES'})}
          <KTSVG
            path={`/media/gori/a1/${show.hasNotice ? 'hasNotice' : 'noNotice'}.svg`}
            className='svg-icon-1 ms-2 text-gray-700'
          />
        </span>
      </PageTitle>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        {loadingFirst ? (
          <TableWrapper>
            <TableBody>
              <div className='my-5'>
                <div className='btn col-6 col-sm-3 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
              <TableSkeleton countColumn={4} />
            </TableBody>
          </TableWrapper>
        ) : (
          <>
            <TableTabs
              dataTabs={statistic}
              keyCheckActive='status'
              children={
                <TableWrapper className='rounded-top-left-0-dt'>
                  <TableHeader className='min-h-1px my-5 mb-8 d-flex flex-column'>
                    <A1BatchesFilter />
                  </TableHeader>
                  <TableBody>
                    <Table
                      columns={columns}
                      data={tableData}
                      pagination={pagination}
                      usePagination
                    />
                  </TableBody>
                </TableWrapper>
              }
            />
          </>
        )}
      </CSSTransition>
    </>
  )
}

export {A1BatchesPage}
