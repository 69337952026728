import clsx from 'clsx'
import {getIn} from 'formik'
import {forwardRef, useMemo, useState} from 'react'

type Props = {
  tabIndex?: any
  className?: string
  labelClassName?: string | null
  label?: string | null
  openVisibility?: boolean
  openHighlight?: boolean
  strengthMessage?: string | null
  required?: boolean
  formik: any
  name: string
  onKeyDown?: any
}

const InputPassword = forwardRef<HTMLInputElement, Props>(
  (
    {
      tabIndex,
      className,
      labelClassName = null,
      label = null,
      openVisibility = true,
      openHighlight = true,
      strengthMessage = null,
      required = false,
      formik,
      name,
      onKeyDown = null,
    },
    ref
  ) => {
    let fieldProps = formik.getFieldProps(name)
    let formikErrors = getIn(formik.errors, name)
    let formikTouched = getIn(formik.touched, name)

    const [visibility, setVisibility] = useState(false)

    const password = useMemo(() => {
      const password = formik.getFieldProps(name).value
      let level
      if (/[a-z]/.test(password) || /[A-Z]/.test(password)) {
        if (/[a-z]/.test(password) && /[A-Z]/.test(password)) {
          level = 1
        } else {
          level = 2
        }
      } else {
        level = 3
      }
      return {level: level, length: password.length}
    }, [formik, name])

    return (
      <div className={clsx('fv-row fv-plugins-icon-container', className)}>
        <div className='mb-1'>
          {label && (
            <label
              className={clsx('form-label fw-bolder text-dark fs-6', labelClassName, {
                required: required,
              })}
            >
              {label}
            </label>
          )}
          <div className='position-relative mb-3'>
            <input
              tabIndex={tabIndex}
              autoComplete='new-password'
              className={clsx(
                'form-control pe-14',
                {'border-danger': formikTouched && formikErrors},
                {'border-success': formikTouched && !formikErrors && fieldProps.value}
              )}
              type={visibility ? 'text' : 'password'}
              onKeyDown={onKeyDown}
              {...fieldProps}
              ref={ref}
            />
            <span
              className={clsx(
                'btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2',
                {
                  'd-none': !openVisibility,
                }
              )}
              onClick={() => setVisibility(!visibility)}
            >
              {visibility ? (
                <i
                  className={clsx('bi bi-eye fs-2', {
                    'text-danger': formikTouched && formikErrors,
                  })}
                />
              ) : (
                <i
                  className={clsx('bi bi-eye-slash fs-2', {
                    'text-danger': formikTouched && formikErrors,
                  })}
                />
              )}
            </span>
          </div>
          <div
            className={clsx('d-flex align-items-center mb-3', {
              'd-none': !openHighlight,
            })}
          >
            {Array.from({length: 4}).map((_, index) => (
              <div
                key={index}
                className={clsx('flex-grow-1 rounded h-5px me-2 bg-secondary', {
                  'bg-danger':
                    password.level === 3 && password.length >= index * 4 && fieldProps.value,
                  'bg-warning':
                    password.level === 2 && password.length >= index * 4 && fieldProps.value,
                  'bg-success':
                    password.level === 1 && password.length >= index * 4 && fieldProps.value,
                })}
              />
            ))}
          </div>
        </div>
        <div className='text-muted'>{strengthMessage}</div>
        {formikTouched && formikErrors && (
          <div className='fv-plugins-message-container mt-2'>
            <div className='fv-help-block text-danger'>
              <span role='alert'>{formikErrors}</span>
            </div>
          </div>
        )}
      </div>
    )
  }
)

export {InputPassword}
