import clsx from 'clsx'
import {useFormik} from 'formik'
import {find, get, map, reduce, some} from 'lodash'
import React, {useMemo, useState} from 'react'
import {Container, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {ConfirmActionModal} from '../../../../../../gori/components'
import {useCancelToken, UseYupValidate} from '../../../../../../gori/hooks'
import {useA1BatchesProvider} from '../../../../../../gori/providers'
import {Button, SelectFormik, ValidationErrorModal} from '../../../../../../gori/widgets'
import {A1_CONSTANTS, A1BatchService} from '../../../../a1'

type Props = {
  show: boolean
  data: {
    selectAll: boolean
    selectedList: any
    type: string
  } | null
  handleClose: () => void
  reloadTable: () => void
}

const MassUpdateModal: React.FC<Props> = ({show, data, handleClose, reloadTable}) => {
  const intl = useIntl()
  const {a1Batch} = useA1BatchesProvider()
  const {stringYup} = UseYupValidate()
  const {newCancelToken, isCancel} = useCancelToken()

  const [validationErrors, setValidationErrors] = useState<any>()
  const [loading, setLoading] = useState({update: false})
  const [showModal, setShowModal] = useState({confirm: false})
  const [hasPass, setHasPass] = useState({release: false})

  const handleCloseModal = () => handleClose()

  const initValidateSchema = useMemo(
    () => ({
      type: Yup.string(),
      release_status: Yup.string()
        .nullable()
        .when('type', {
          is: (type) => type === A1_CONSTANTS.MASS_UPDATE.RELEASE_STATUS.value,
          then: stringYup(255, 'RELEASE_STATUS'),
        }),
    }),
    [stringYup]
  )

  const selected = useMemo(() => {
    const selectedOrders = get(data, 'selectedList.selected', [])
    const noStatus = some(selectedOrders, (order) => !get(order, 'original.release_status'))
    const checkSomeHold = some(
      selectedOrders,
      (order) =>
        get(order, 'original.release_status') === A1_CONSTANTS.OPTIONS_RELEASE_STATUS.HOLD.value
    )

    const optionsMassUpdate = map(A1_CONSTANTS.OPTIONS_RELEASE_STATUS, (option) => ({
      ...option,
      isDisabled: !(
        (option.value === A1_CONSTANTS.OPTIONS_RELEASE_STATUS.RELEASED.value &&
          (checkSomeHold || noStatus)) ||
        (option.value === A1_CONSTANTS.OPTIONS_RELEASE_STATUS.HOLD.value && noStatus) ||
        data?.selectAll
      ),
    }))

    const ids = reduce(
      selectedOrders,
      (acc: any, order) => {
        const releaseStatus = get(order, 'original.release_status')
        const id = get(order, 'original.id')

        if (releaseStatus === A1_CONSTANTS.OPTIONS_RELEASE_STATUS.HOLD.value) {
          acc.hold.push(id)
        } else if (!releaseStatus) {
          acc.noStatus.push(id)
        }
        return acc
      },
      {hold: [], noStatus: []}
    )

    return {ids, optionsMassUpdate}
  }, [data])

  const formik = useFormik({
    initialValues: {
      type: data?.type,
      release_status: null,
    },
    validationSchema: Yup.object().shape(initValidateSchema),
    onSubmit: async (values) => {
      const config = {cancelToken: newCancelToken()}
      const payload = {
        order_ids:
          values.release_status === A1_CONSTANTS.OPTIONS_RELEASE_STATUS.RELEASED.value
            ? [...selected.ids.hold, ...selected.ids.noStatus]
            : selected.ids.noStatus,
        batch_id: a1Batch.id,
        all: data?.selectAll,
        release_status: values.release_status,
      }

      if (
        values.release_status === A1_CONSTANTS.OPTIONS_RELEASE_STATUS.RELEASED.value &&
        !hasPass.release
      ) {
        return setShowModal((prev) => ({...prev, confirm: true}))
      }

      try {
        setLoading((prev) => ({...prev, update: true}))
        const {message}: any = await A1BatchService.releasedOrder(payload, config)
        if (message) {
          toast.success(message)
          reloadTable()
          handleClose()
        }
      } catch (error: any) {
        if (!isCancel(error)) setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, update: false}))
      }
    },
  })

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => setValidationErrors(undefined)}
          response={validationErrors}
        />
      )}
      {showModal.confirm && (
        <ConfirmActionModal
          show={showModal.confirm}
          title={intl.formatMessage({id: 'RELEASE_ORDER'})}
          message={
            <span
              dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'RELEASE_ORDER_CONFIRM'})}}
            />
          }
          messagePrimary={intl.formatMessage({id: 'CONFIRM'})}
          messageSecondary={intl.formatMessage({id: 'CANCEL'})}
          handlePrimaryAction={() => {
            setHasPass((prev) => ({...prev, release: true}))
            setShowModal((prev) => ({...prev, confirm: false}))
            formik.handleSubmit()
          }}
          handleSecondaryAction={() => setShowModal((prev) => ({...prev, confirm: false}))}
          handleClose={() => setShowModal((prev) => ({...prev, confirm: false}))}
        />
      )}
      <Modal
        id='gori_modal_mass_update_order'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-600px h-auto'
        show={show && !showModal.confirm}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix='fw-bolder fs-1'>
              {intl.formatMessage({id: 'MASS_UPDATE'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className='d-flex justify-content-between align-items-center'>
                <span className='fs-2 fw-bolder'>
                  {intl.formatMessage({
                    id: find(A1_CONSTANTS.MASS_UPDATE, {value: data?.type})?.label || 'MASS_UPDATE',
                  })}
                </span>
                <span className='fw-bold text-primary'>
                  {intl.formatMessage(
                    {id: 'THIS_WILL_APPLY_TO_INPUT_SELECTED_ORDERS'},
                    {
                      input: data?.selectAll
                        ? data?.selectedList?.total
                        : formik?.values?.release_status ===
                          A1_CONSTANTS.OPTIONS_RELEASE_STATUS.RELEASED.value
                        ? [...selected.ids.hold, ...selected.ids.noStatus].length
                        : selected.ids.noStatus.length,
                    }
                  )}
                </span>
              </div>
              <SelectFormik
                emptyDefault={false}
                formik={formik}
                name='release_status'
                className={clsx('fw-bold fs-5')}
                placeholder={intl.formatMessage({id: 'PLEASE_CHOOSE'})}
                options={selected.optionsMassUpdate}
                required
                hasUseIntl
              />
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'UPDATE'})}
                event={formik.handleSubmit}
                disabled={loading.update}
                loading={loading.update}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {MassUpdateModal}
