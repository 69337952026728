import {useEffect, useRef} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {MenuComponent, SwapperComponent} from '../assets/ts/components'
import {initialState, reducer} from '../context'
import {AsideLayout, Content, Footer, HeaderWrapper, PageDataProvider, ScrollTop} from '../layout'
import {AsideProvider, MeterProvider, StateProvider, StatesGlobalProvider} from '../providers'

const MasterLayout = () => {
  const location = useLocation()
  const timer: any = useRef()

  useEffect(() => {
    timer.current = setTimeout(() => {
      SwapperComponent.reinitialization()
      MenuComponent.reinitialization()
    }, 100)
    return () => {
      clearTimeout(timer.current)
    }
  }, [location.key])

  return (
    <PageDataProvider>
      <MeterProvider>
        <StateProvider initialState={initialState} reducer={reducer}>
          <StatesGlobalProvider>
            <div className='d-flex flex-column flex-root'>
              <div className='page d-flex flex-row flex-column-fluid'>
                <AsideProvider>
                  <AsideLayout />
                </AsideProvider>
                {/* <AsideDefault /> */}
                <div className='wrapper d-flex flex-column flex-row-fluid' id='gori_wrapper'>
                  <HeaderWrapper />
                  <div id='gori_content' className='content d-flex flex-column flex-column-fluid'>
                    <Content>
                      <Outlet />
                    </Content>
                  </div>
                  <Footer />
                </div>
              </div>
            </div>
            <ScrollTop />
          </StatesGlobalProvider>
        </StateProvider>
      </MeterProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
