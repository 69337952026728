import {Suspense} from 'react'
import {Outlet, useNavigate} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import {globalRouter} from '../gori/helpers'
import {I18nProvider} from '../gori/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen, MasterInit} from '../gori/layout'
import {AuthInit} from '../gori/providers'
import CookieBanner from './modules/common/modals/CookieModal'

const App = () => {
  const navigate = useNavigate()
  globalRouter.navigate = navigate

  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
              <CookieBanner />
              <ToastContainer position='top-right' autoClose={30000} />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </>
  )
}

export {App}
