import {AxiosRequestConfig} from 'axios'
import axiosConfig from '../../../../app/api/axiosConfig'

const URL = '/filters'

const getCustomColumns = (config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.get(URL, config)
}

const saveCustomColumns = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(URL, payload, config)
}

const updateCustomColumns = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.put(URL, payload, config)
}

export const TableService = {
  getCustomColumns,
  saveCustomColumns,
  updateCustomColumns,
}
