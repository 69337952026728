/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {Link, Outlet, Route, Routes} from 'react-router-dom'
import {SwapperComponent} from '../../../../gori/assets/ts/components'
import {toAbsoluteUrl} from '../../../../gori/helpers'
import {Error404, Error500} from '..'

const ErrorsLayout = () => {
  const intl = useIntl()

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
        <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-20'>
          <a
            href='/a1-batches'
            onClick={() => SwapperComponent.removeInstances()}
            className='mb-10 pt-lg-20'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/favicon.ico')}
              className='h-100px mb-5'
            />
          </a>
          <div className='pt-lg-10 mb-10'>
            <Outlet />
            <div className='text-center'>
              <Link to='/' className='btn btn-lg btn-primary fw-bolder'>
                {intl.formatMessage({id: 'GO_TO_HOMEPAGE'})}
              </Link>
            </div>
          </div>
          <div
            className='
          d-flex
          flex-row-auto
          bgi-no-repeat
          bgi-position-x-center
          bgi-size-contain
          bgi-position-y-bottom
          min-h-100px min-h-lg-200px
        '
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/gori/errors/bg.png')}')`,
            }}
          ></div>
        </div>

        <div className='d-flex flex-center flex-column-auto p-10'>
          <div className='d-flex align-items-center fw-bold fs-6'>
            <a href='https://gori.ai' className='text-muted text-hover-primary px-2'>
              {intl.formatMessage({id: 'ABOUT'})}
            </a>
            <Link to='/support' className='text-muted text-hover-primary px-2'>
              {intl.formatMessage({id: 'CONTACT_US'})}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

const ErrorsPage = () => (
  <Routes>
    <Route element={<ErrorsLayout />}>
      <Route path='404' element={<Error404 />} />
      <Route path='500' element={<Error500 />} />
      <Route index element={<Error404 />} />
    </Route>
  </Routes>
)

export {ErrorsPage}
