import {AxiosRequestConfig} from 'axios'
import axiosConfig from '../../../api/axiosConfig'

const BASE_URL = 'profile'
const URL_SHIPPING_ADDRESS = '/shipping-addresses'

// User
function getUser(config?: AxiosRequestConfig | undefined) {
  return axiosConfig.get(`/${BASE_URL}`, config)
}

// const updateLanguage = (userId, payload, config?: AxiosRequestConfig | undefined) => {
//   return axiosConfig.put(`/users/profile/general/${userId}`, payload, config)
// }

// addresses
const getAddress = (config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.get(`${URL_SHIPPING_ADDRESS}`, config)
}

const saveAddress = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL_SHIPPING_ADDRESS}`, payload, config)
}

const editAddress = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.put(`${URL_SHIPPING_ADDRESS}`, payload, config)
}

const validateAddress = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL_SHIPPING_ADDRESS}/validate-address`, payload, config)
}

export const CommonService = {
  // User
  getUser,
  // updateLanguage,
  // Address
  getAddress,
  saveAddress,
  editAddress,
  validateAddress,
}
