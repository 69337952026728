import clsx from 'clsx'
import {FormikProps, getIn} from 'formik'
import {FC, useMemo, ChangeEvent, FocusEvent, KeyboardEvent} from 'react'

type ConfigAppend = {
  name: string
  position: 'right' | 'left'
  classInput?: string
  classAppend?: string
  hasEvent?: boolean
  handleSubmit?: () => void
}

type Props = {
  className?: string
  labelClassName?: string
  inputClassName?: string
  label?: string
  size?: 'sm' | 'lg'
  type?: 'text' | 'number'
  placeholder?: string
  required?: boolean
  formik: FormikProps<any>
  name: string
  min?: number
  max?: number
  isInteger?: boolean
  isPositive?: boolean
  checkFormik?: boolean
  disabled?: boolean
  maxlength?: number
  configAppend?: ConfigAppend
  formatDate?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onFocus?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onKeyDown?: (event: KeyboardEvent) => void
}

const InputTextFormik: FC<Props> = ({
  className,
  labelClassName,
  inputClassName,
  label,
  size,
  type = 'text',
  placeholder,
  required = false,
  formik,
  name,
  min,
  max,
  isInteger = false,
  isPositive = true,
  checkFormik = true,
  disabled = false,
  maxlength,
  configAppend,
  formatDate = false,
  onChange,
  onKeyDown,
  onFocus,
}) => {
  const fieldProps = formik.getFieldProps(name)
  const formikErrors = getIn(formik.errors, name)
  const formikTouched = getIn(formik.touched, name)

  const {isInvalid, isValid} = useMemo(() => {
    const invalid = formikTouched && formikErrors && checkFormik
    const valid = formikTouched && !formikErrors && fieldProps.value && checkFormik
    return {isInvalid: invalid, isValid: valid}
  }, [formikTouched, formikErrors, fieldProps.value, checkFormik])

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let {value} = event.target
    if (isPositive && type === 'number' && Number(value) < 0) {
      value = ''
    }

    formik.setFieldValue(name, value)

    if (formatDate) {
      const formattedValue = value.replace(/\D/g, '').replace(/^(\d{2})(\d{2})/, '$1/$2')
      formik.setFieldValue(name, formattedValue)
    }

    onChange?.(event)
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (
      type === 'number' &&
      ['e', 'E', '-', '+', '.'].includes(event.key) &&
      ((['-', '+'].includes(event.key) && isPositive) ||
        (['.'].includes(event.key) && isInteger) ||
        ['e', 'E'].includes(event.key))
    ) {
      event.preventDefault()
    }
    onKeyDown?.(event)
  }

  const handleFocus = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (+event.target.value === 0) formik.setFieldValue(name, '')
    onFocus?.(event)
  }

  const handleAppendClick = () => {
    if (configAppend?.hasEvent && isValid) configAppend.handleSubmit?.()
  }

  const commonInputProps = {
    ...fieldProps,
    className: clsx('form-control input-custom__input', inputClassName, {
      [`form-control-${size}`]: size,
      'cursor-no-drop': disabled,
      'is-invalid': isInvalid,
      'is-valid': isValid,
      [configAppend?.classInput ?? '']: !!configAppend?.classInput,
    }),
    disabled,
    maxLength: maxlength,
    placeholder,
    onKeyDown: handleKeyDown,
    onChange: handleChange,
    onFocus: handleFocus,
    type,
    min,
    max,
  }

  return (
    <>
      {label && <label className={clsx('form-label', labelClassName, {required})}>{label}</label>}
      <div className={className}>
        <div className='input-custom'>
          <input {...commonInputProps} />
          {configAppend && (
            <span
              className={clsx(
                `input-custom__append input-custom__append--${configAppend.position}`,
                {
                  'cursor-pointer text-hover-primary': configAppend.hasEvent && isValid,
                  'cursor-no-drop': configAppend.hasEvent && isInvalid,
                  [configAppend.classAppend ?? '']: !!configAppend.classAppend,
                }
              )}
              onClick={handleAppendClick}
            >
              {configAppend.name}
            </span>
          )}
        </div>
        {isInvalid && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger' role='alert'>
              {formikErrors}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export {InputTextFormik}
