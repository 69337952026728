import clsx from 'clsx'
import {getIn, useFormik} from 'formik'
import {compact, isEmpty, startCase, toLower, toUpper} from 'lodash'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {FORMAT_DATE, OPTION_COUNTRIES} from '../../../../../gori/constants'
import {convertUserTimeZone} from '../../../../../gori/helpers'
import {useAuth} from '../../../../../gori/providers'
import {Button, InputTextFormik, SelectFormik} from '../../../../../gori/widgets'
import {FromOrToModal} from '../../../common'
import {A1_CONSTANTS} from '../../core/constants'

type Props = {
  show: boolean
  data: any
  handleClose: () => void
}

const EditOrderInfo: React.FC<Props> = ({show, data, handleClose}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const row = useRef({parcels: 0, order_items: -1})
  const [fromToModal, setFormToModal] = useState<{
    show: boolean
    name: 'from' | 'to'
    data: any
    validate: any
  }>({
    show: false,
    name: 'to',
    data: {},
    validate: {},
  })

  const initialValues = useMemo(() => {
    return {
      to: {
        to_company: data?.order_ext?.to_company || null,
        to_first_name: data?.order_ext?.to_first_name || null,
        to_last_name: data?.order_ext?.to_last_name || null,
        to_street1: data?.order_ext?.to_street1 || null,
        to_street2: data?.order_ext?.to_street2 || null,
        to_city: data?.order_ext?.to_city || null,
        to_state: data?.order_ext?.to_state || null,
        to_zip: data?.order_ext?.to_zip || null,
        to_country: data?.order_ext?.to_country || null,
        to_phone: data?.order_ext?.to_phone || null,
        to_email: data?.order_ext?.to_email || null,
        to_is_residential: !!data?.order_ext?.to_is_residential || false,
      },
      service: data?.carrier || data?.service ? `${data?.carrier}_${data?.service}` : null,
      parcels: data?.order_parcels,
      check_weight: false,
      container_id: data?.container_id || null,
      reference1: data?.reference_1 || null,
      reference2: data?.reference_2 || null,
      order_items: {},
    }
  }, [data])

  const formik = useFormik({
    initialValues,
    enableReinitialize: false,
    onSubmit: () => {},
  })

  const handleCloseFromToModal = () => {
    setFormToModal({
      show: false,
      name: 'to',
      data: {},
      validate: {},
    })
  }

  const handleSaveModal = (name, values) => {
    formik.setFieldValue(name, values)
    handleCloseFromToModal()
  }

  // BEGIN: Action Order Items
  useEffect(() => {
    if (isEmpty(data?.order_items)) return

    const initOrderItems = async () => {
      const orderItems = {}

      data?.order_items.forEach((item) => {
        row.current.order_items++
        orderItems[row.current.order_items] = {
          id: item?.id || null,
          content: item?.a1_content || null,
          description: item?.a1_description || null,
          hs_tariff_number: item?.a1_hs_tariff_number || null,
          country: item?.a1_country || null,
          weight: item?.a1_weight || null,
          quantity: item?.a1_quantity || null,
          value: item?.a1_value || null,
        }
      })

      await formik.setFieldValue('order_items', {
        ...formik.values.order_items,
        ...orderItems,
      })
    }

    initOrderItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.initialValues])
  // END: Action Order Items

  // BEGIN: Ship To
  const shipToShow = useMemo(() => {
    const {to_street1, to_street2, to_city, to_state, to_zip} = formik.values.to
    const addressTo = compact([
      to_street1,
      to_street2,
      to_city,
      to_state && to_zip ? `${to_state} ${to_zip}` : to_state || to_zip,
    ]).join(', ')
    return addressTo
  }, [formik.values.to])

  const checkValidateFailed = useMemo(() => {
    if (!formik.values.to.to_company && !formik.values.to.to_first_name) {
      return true
    }
    const validate = ['to_city', 'to_state', 'to_country', 'to_zip', 'to_street1']
    return validate.some((item) => !formik.values.to[item])
  }, [formik.values])
  // END: Ship To

  // BEGIN: Total Weight
  const totalWeightPackage = useMemo(
    () =>
      Object.values(formik.values.parcels).reduce(
        (sum: number, parcel: any) => sum + (parseFloat(parcel?.weight) || 0),
        0
      ),
    [formik.values.parcels]
  )
  // END: Total Weight

  const convertToTitleCase = (input, showFirst = true) => {
    if (isEmpty(input)) return null
    const [firstPart, ...rest] = input.split('_')
    const firstPartUpper = toUpper(firstPart)
    const restTitleCase = startCase(toLower(rest.join('_')))
    return showFirst ? `${firstPartUpper} ${restTitleCase}` : restTitleCase
  }

  return (
    <>
      {fromToModal.show && fromToModal.name === 'to' && (
        <FromOrToModal
          data={fromToModal}
          handleSave={handleSaveModal}
          handleClose={handleCloseFromToModal}
          labelModal={intl.formatMessage({id: 'SHIPPING_ADDRESS'})}
          disabled={true}
        />
      )}
      <Modal
        id='gori_modal_edit_order_info'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-800px h-auto'
        show={show && !fromToModal.show}
        backdrop='static'
        onHide={handleClose}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'ORDER_INFORMATION'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={clsx('vh-75 scroll-y', {'cursor-no-drop': true})}>
            <div className='d-flex flex-column gap-4 px-10 px-md-4'>
              <div className='d-flex flex-column flex-md-row col-12'>
                <div className='col-12 col-md-6'>
                  <label className='form-label col-3 col-md-6'>
                    {intl.formatMessage({id: 'DATE'})}
                  </label>
                  <span className='col-8 text-dark fw-bolder'>
                    {convertUserTimeZone(data?.created_at, currentUser, FORMAT_DATE.DATE)}
                  </span>
                </div>
                <div className='col-12 col-md-6'>
                  <label className='form-label col-3'>{intl.formatMessage({id: 'BAE_ID'})}</label>
                  <span className='col-8 text-dark fw-bolder'>{data?.bae_id}</span>
                </div>
              </div>
              <div className='d-flex flex-column'>
                <div className='d-flex'>
                  <label className='form-label col-form-label required col-3'>
                    {intl.formatMessage({id: 'SHIP_TO'})}
                  </label>
                  <div className='input-custom col-6'>
                    <input
                      value={shipToShow}
                      className={clsx('form-control input-custom__input cursor-pointer', {
                        'is-invalid': getIn(formik.touched, 'to') && checkValidateFailed,
                        'is-valid': getIn(formik.touched, 'to') && !checkValidateFailed,
                      })}
                      onClick={() => {
                        setFormToModal({
                          show: true,
                          name: 'to',
                          data: formik.getFieldProps('to').value,
                          validate: {},
                        })
                      }}
                    />
                  </div>
                  <div className='flex-fill d-flex align-items-center justify-content-center'>
                    <span
                      className='text-decoration-underline fw-bold text-primary cursor-pointer'
                      onClick={() => {
                        setFormToModal({
                          show: true,
                          name: 'to',
                          data: formik.getFieldProps('to').value,
                          validate: {},
                        })
                      }}
                    >
                      {intl.formatMessage({id: 'VIEW'})}
                    </span>
                  </div>
                </div>
                <div className='d-flex mt-1'>
                  <div className='col-3' />
                  {checkValidateFailed && getIn(formik.touched, 'to') && (
                    <span className='text-danger fs-7'>
                      {intl.formatMessage(
                        {id: 'INPUT_IS_REQUIRED'},
                        {input: intl.formatMessage({id: 'SHIP_TO'})}
                      )}
                    </span>
                  )}
                </div>
              </div>
              <div className={clsx('d-flex flex-column gap-4', {'pe-none': true})}>
                <div className='d-flex col-12'>
                  <SelectFormik
                    className='col-6'
                    labelClassName='col-3 col-form-label'
                    label={intl.formatMessage({id: 'SERVICE'})}
                    options={[
                      {
                        label: convertToTitleCase(formik.values.service),
                        value: formik.values.service,
                      },
                    ]}
                    formik={formik}
                    name='service'
                    required
                    emptyDefault={false}
                  />
                </div>
                <div className='d-flex flex-column flex-md-row col-12'>
                  <SelectFormik
                    options={[
                      {
                        label:
                          convertToTitleCase(formik.values.parcels[0].package_type, false) ||
                          intl.formatMessage({id: 'PACKAGE_TYPE'}),
                        value: formik.values.parcels[0].package_type,
                      },
                    ]}
                    className={'col-6'}
                    labelClassName='col-12 col-md-3 col-form-label'
                    label={intl.formatMessage({id: 'PACKAGE_INFO'})}
                    formik={formik}
                    name='parcels.0.package_type'
                    placeholder={intl.formatMessage({id: 'PACKAGE_TYPE'})}
                    required
                  />
                </div>
                <div className='d-flex col-12'>
                  <div className='col-0 col-md-3' />
                  <div className='flex-fill'>
                    {Object.entries(formik.getFieldProps('parcels').value).map(
                      ([keyItem, valueItem]: any, index) => {
                        const packageType = formik.values.parcels[0].package_type

                        return (
                          <div key={index}>
                            <div className='row'>
                              <div className='col-12 d-flex flex-wrap'>
                                {!isEmpty(packageType) ? (
                                  <div className='fs-5 fw-bold text-muted'>
                                    {intl.formatMessage({id: 'DIMENSIONS'})}:{' '}
                                    {`${valueItem?.length} x ${valueItem?.width} x ${valueItem?.height} ${valueItem?.dimension_unit}`}
                                  </div>
                                ) : (
                                  <>
                                    {['length', 'width', 'height'].map((item, index) => {
                                      return (
                                        <div className='col-6 col-sm-3' key={index}>
                                          <InputTextFormik
                                            type='number'
                                            min={0}
                                            className={'me-2'}
                                            labelClassName={'text-muted'}
                                            label={item.charAt(0).toUpperCase()}
                                            formik={formik}
                                            name={`parcels.${keyItem}.${item}`}
                                          />
                                        </div>
                                      )
                                    })}
                                    <div className='col-6 col-sm-3'>
                                      <SelectFormik
                                        placeholder=''
                                        label={intl.formatMessage({id: 'UNIT'})}
                                        labelClassName={'text-muted'}
                                        options={A1_CONSTANTS.OPTION_DIMENSION_UNIT}
                                        name={`parcels.${keyItem}.dimension_unit`}
                                        formik={formik}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className='col-12 d-flex flex-wrap mt-3'>
                                <div className='col-6 col-sm-3' key={index}>
                                  <InputTextFormik
                                    type='number'
                                    min={0}
                                    className={'me-2'}
                                    labelClassName={'text-muted'}
                                    label={intl.formatMessage({id: 'WEIGHT'})}
                                    formik={formik}
                                    name={`parcels.${keyItem}.weight`}
                                  />
                                </div>
                                <div className='col-6 col-sm-3'>
                                  <SelectFormik
                                    label={intl.formatMessage({id: 'UNIT'})}
                                    placeholder=''
                                    labelClassName={'text-muted'}
                                    options={A1_CONSTANTS.OPTION_WEIGHT_UNIT}
                                    name={`parcels.${keyItem}.weight_unit`}
                                    formik={formik}
                                  />
                                </div>
                                <div className='col-12 col-md-6 d-flex flex-column justify-content-end align-items-end'>
                                  <div className='d-flex align-items-center text-muted mt-3'>
                                    {intl.formatMessage({id: 'TOTAL_WEIGHT'})}:
                                    <span className='text-dark fs-1 mx-2'>
                                      {totalWeightPackage}
                                    </span>{' '}
                                    {formik?.values?.parcels[0]?.weight_unit?.toUpperCase()}
                                  </div>
                                  {formik.errors?.check_weight && (
                                    <div className='text-danger d-flex justify-content-end ps-10'>
                                      {formik.errors?.check_weight}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    )}
                  </div>
                </div>
                <div className='d-flex col-12 col-md-6'>
                  <InputTextFormik
                    className='col-6'
                    labelClassName='col-6 col-form-label'
                    label={intl.formatMessage({id: 'CONTAINER_ID'})}
                    formik={formik}
                    name={'container_id'}
                  />
                </div>
                <div className='d-flex flex-column flex-md-row col-12 gap-4'>
                  <div className='d-flex col-12 col-md-6'>
                    <InputTextFormik
                      labelClassName='col-6 col-form-label'
                      label={intl.formatMessage({id: 'REF_1'})}
                      formik={formik}
                      name={'reference1'}
                    />
                  </div>
                  <div className='d-flex col-12 col-md-6'>
                    <InputTextFormik
                      labelClassName='col-6 col-form-label text-start text-md-center'
                      label={intl.formatMessage({id: 'REF_2'})}
                      formik={formik}
                      name={'reference2'}
                    />
                  </div>
                </div>
                <hr className='text-gray-700' />
                <div>
                  {!isEmpty(formik?.values?.order_items) &&
                    Object.entries(formik?.values?.order_items).map(([key, value], index) => (
                      <div
                        className='bg-light rounded-2 p-5 mb-6 d-flex flex-column gap-4'
                        key={index}
                      >
                        <div className='d-flex justify-content-between align-items-center fs-5 fw-bolder'>
                          {intl.formatMessage({id: 'ITEM'})} {index + 1}
                        </div>
                        <div className='d-flex'>
                          <SelectFormik
                            className='col-6'
                            labelClassName='col-6 col-md-3 col-form-label'
                            label={intl.formatMessage({id: 'CONTENT'})}
                            formik={formik}
                            options={A1_CONSTANTS.OPTIONS_CONTENT_TYPE}
                            required
                            name={`order_items.${key}.content`}
                            hasUseIntl={true}
                            emptyDefault={false}
                          />
                        </div>
                        <div className='d-flex'>
                          <InputTextFormik
                            className='col-6'
                            labelClassName='col-6 col-md-3 col-form-label'
                            label={intl.formatMessage({id: 'DESCRIPTION'})}
                            formik={formik}
                            required
                            name={`order_items.${key}.description`}
                          />
                        </div>
                        <div className='d-flex'>
                          <InputTextFormik
                            className='col-6'
                            labelClassName='col-6 col-md-3 col-form-label'
                            label={intl.formatMessage({id: 'HTS_CODE'})}
                            formik={formik}
                            required
                            name={`order_items.${key}.hs_tariff_number`}
                          />
                        </div>
                        <div className='d-flex'>
                          <SelectFormik
                            className='col-6'
                            labelClassName='col-6 col-md-3 col-form-label'
                            label={intl.formatMessage({id: 'COUNTRY_OF_ORIGIN'})}
                            formik={formik}
                            options={OPTION_COUNTRIES}
                            required
                            name={`order_items.${key}.country`}
                            emptyDefault={false}
                          />
                        </div>
                        <div className='d-flex flex-column flex-md-row gap-4 gap-md-0'>
                          <div className='d-flex col-12 col-md-6'>
                            <InputTextFormik
                              className='col-6'
                              labelClassName='col-6 col-form-label'
                              label={intl.formatMessage({id: 'QUANTITY'})}
                              formik={formik}
                              required
                              name={`order_items.${key}.quantity`}
                            />
                          </div>
                          <div className='d-flex col-12 col-md-6'>
                            <InputTextFormik
                              className='col-6'
                              labelClassName='col-6 col-form-label text-start text-md-center'
                              label={intl.formatMessage({id: 'VALUE_$'})}
                              formik={formik}
                              required
                              name={`order_items.${key}.value`}
                            />
                          </div>
                        </div>
                        <div className='d-flex'>
                          <InputTextFormik
                            className='col-6 col-md-3'
                            labelClassName='col-6 col-md-3 col-form-label'
                            label={`${intl.formatMessage({id: 'WEIGHT'})} (Kg)`}
                            formik={formik}
                            required
                            name={`order_items.${key}.weight`}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleClose}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {EditOrderInfo}
