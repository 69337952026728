import {AxiosRequestConfig} from 'axios'
import axiosConfig from '../../../api/axiosConfig'

function login(payload: any, config?: AxiosRequestConfig | undefined) {
  return axiosConfig.post(`/login`, payload, config)
}

export const AuthService = {
  login,
}
