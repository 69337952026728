import {lazy} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {UseRoutesPermission} from '../../gori/hooks'
import {MasterLayout} from '../../gori/layout'
import {SuspenseView} from './AppRoutes'

const PrivateRoutes = () => {
  const A1Wrapper = lazy(() => import('../pages/a1/A1Wrapper'))
  const SupportWrapper = lazy(() => import('../pages/support/SupportWrapper'))

  const {routes, firstPage} = UseRoutesPermission()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {routes.A1_BATCHES.hasPermission && (
          <Route
            path='a1-batches/*'
            element={
              <SuspenseView>
                <A1Wrapper />
              </SuspenseView>
            }
          />
        )}
        {routes.SUPPORTS.hasPermission && (
          <Route
            path='support/*'
            element={
              <SuspenseView>
                <SupportWrapper />
              </SuspenseView>
            }
          />
        )}
        {/* Redirect to Dashboard for all pages that are not available */}
        <Route path='*' element={<Navigate to={firstPage?.route ?? '/support'} />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
