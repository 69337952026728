/* eslint-disable no-undef */
import axios from 'axios'
import {getAuth, globalRouter} from '../../gori/helpers'
import HttpStatusCode from './HttpStatusCode'

const API_VERSION = process.env.REACT_APP_API_VERSION
const API_URL = process.env.REACT_APP_API_URL
const BASE_URL = `${API_URL}/${API_VERSION}`

const axiosConfig = axios.create({
  baseURL: BASE_URL,
  timeout: 2 * 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosConfig.interceptors.request.use(
  function (config) {
    const auth = getAuth()
    if (auth?.access_token) {
      config.headers.Authorization = `Bearer ${auth?.access_token}`
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axiosConfig.interceptors.response.use(
  (response) => {
    return response.data
  },
  async function (error) {
    switch (error?.response?.status) {
      case HttpStatusCode.UNAUTHORIZED:
        globalRouter?.navigate && (await globalRouter.navigate('/logout'))
        break
      case HttpStatusCode.NOT_FOUND:
        globalRouter?.navigate && (await globalRouter.navigate('/error/404'))
        break
      case HttpStatusCode.INTERNAL_SERVER_ERROR:
        globalRouter?.navigate && (await globalRouter.navigate('/error/500'))
        break
    }
    return Promise.reject(error)
  }
)

export default axiosConfig
