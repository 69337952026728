import clsx from 'clsx'
import {compact, isEmpty} from 'lodash'
import React, {useCallback, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../gori/components'
import {FORMAT_DATE} from '../../../../../gori/constants'
import {convertUserTimeZone} from '../../../../../gori/helpers'
import {UsePermission} from '../../../../../gori/hooks'
import {useA1BatchesProvider, useAuth} from '../../../../../gori/providers'
import {A1_CONSTANTS} from '../../../a1'

type Props = {
  handleEditConfirmPickupDate?: () => void
  handleEditJourneyInfo?: () => void
}

const JourneyInfoCard: React.FC<Props> = ({handleEditConfirmPickupDate, handleEditJourneyInfo}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {a1Batch} = useA1BatchesProvider()
  const {isOrigin, isDestination} = UsePermission()
  const {PICK_UP_DROP_OFF, AIR_DELIVERY} = A1_CONSTANTS.OPTIONS_JOURNEY_STATUS

  const formattedDate = useCallback(
    (date) => (date ? convertUserTimeZone(date, currentUser, FORMAT_DATE.DATE_TIME) : null),
    [currentUser]
  )

  const formatNumber = useCallback(
    ({number = 0, showDecimal = true}: {number: string | number; showDecimal?: boolean}) => {
      const numberFormat = Number(number)
      if (!numberFormat && !showDecimal) return null
      return intl.formatNumber(numberFormat, {
        style: 'decimal',
        minimumFractionDigits: showDecimal ? 2 : 0,
        maximumFractionDigits: 2,
      })
    },
    [intl]
  )

  const showInfo = useMemo(() => {
    const flights = a1Batch?.journey_flight_infos?.reduce(
      (acc, flight) => ({
        ...acc,
        [flight.type]: flight,
      }),
      {}
    )

    return {
      flights,
      label: {
        go: intl.formatMessage({id: flights?.origin?.atd ? 'ATD' : 'ETD'}),
        arrive: intl.formatMessage({id: flights?.destination?.ata ? 'ATA' : 'ETA'}),
      },
    }
  }, [a1Batch?.journey_flight_infos, intl])

  const showJourneyInfoButton = useMemo(() => {
    return (
      (isOrigin && PICK_UP_DROP_OFF.value[2] === a1Batch?.journey_status) ||
      (isDestination &&
        AIR_DELIVERY.value[0] === a1Batch?.journey_status &&
        showInfo?.flights?.origin?.atd &&
        showInfo?.flights?.destination?.ata)
    )
  }, [
    AIR_DELIVERY.value,
    PICK_UP_DROP_OFF.value,
    a1Batch?.journey_status,
    isDestination,
    isOrigin,
    showInfo?.flights?.destination?.ata,
    showInfo?.flights?.origin?.atd,
  ])

  const showShipFrom = useMemo(() => {
    if (isEmpty(a1Batch?.from)) return null
    const {street1, street2, city, state, zip} = a1Batch?.from
    const addressTo = compact([
      street1,
      street2,
      city,
      state && zip ? `${state} ${zip}` : state || zip,
    ]).join(', ')
    return addressTo
  }, [a1Batch.from])

  return (
    <div className='card me-xxl-4 h-100'>
      <div className='card-header border-0 fw-bolder d-flex justify-content-between align-items-center'>
        <span className='fs-3 required'>{intl.formatMessage({id: 'JOURNEY_INFO'})}</span>
        {a1Batch?.apu_at && PICK_UP_DROP_OFF.value[0] === a1Batch?.journey_status && (
          <div
            className='btn btn-sm btn-light-primary rounded-pill'
            onClick={handleEditConfirmPickupDate}
          >
            {intl.formatMessage({id: 'EDIT'})}
          </div>
        )}
        {showJourneyInfoButton && (
          <div
            className='btn btn-sm btn-light-primary rounded-pill'
            onClick={handleEditJourneyInfo}
          >
            {intl.formatMessage({id: 'EDIT'})}
          </div>
        )}
      </div>
      <div className='card-body bg-light rounded-bottom d-flex flex-column flex-md-row'>
        <div className='col-12 col-md-6'>
          <div className='d-flex pe-md-6 text-primary fs-5 fs-xxl-7 text-center fw-bolder'>
            <div className='col-4'>{intl.formatMessage({id: 'JOURNEY'})}</div>
            <div className='col-4'>{intl.formatMessage({id: 'FLIGHT_NUMBER'})}</div>
            <div className='col-4'>
              {showInfo.label.go} &#8226; {showInfo.label.arrive}
            </div>
          </div>
          <div className='d-flex flex-column min-h-150px mh-250px scroll-y pe-md-6 mt-4'>
            {!isEmpty(a1Batch?.journey_flight_infos) &&
              a1Batch?.journey_flight_infos?.map((flight: any, index) => {
                return (
                  <div
                    className={clsx('d-flex flex-column', {
                      'flex-column-fluid': index !== a1Batch?.journey_flight_infos?.length - 1,
                    })}
                    key={index}
                  >
                    <div className='col-4 text-center'>
                      <div className='fs-1 fw-boldest'>{flight?.airport}</div>
                    </div>
                    {index !== a1Batch?.journey_flight_infos?.length - 1 && (
                      <div className='d-flex flex-fill'>
                        <div className='col-4 d-flex flex-column justify-content-center align-items-center'>
                          <div className='border-dashed border-gray-600 border-2 flex-fill h-100' />
                        </div>
                        <div className='col-4 d-flex align-items-center justify-content-center fw-bolder gap-2'>
                          <KTSVG
                            path='/media/gori/a1/flight.svg'
                            className='svg-icon-1 text-gray-700'
                          />
                          <span className='fs-4 fs-xxl-6 text-truncate'>
                            {flight?.flight_number}
                          </span>
                        </div>
                        <div className='col-4 d-flex flex-column justify-content-center text-center fs-6 fs-xxl-8 fw-bold gap-2 text-gray-700'>
                          <span>{formattedDate(flight?.atd || flight?.etd)}</span>
                          {flight?.est_duration && (
                            <span className='fw-boldest text-dark'>{flight?.est_duration}h</span>
                          )}
                          <span>
                            {formattedDate(
                              a1Batch?.journey_flight_infos[index + 1]?.ata ||
                                a1Batch?.journey_flight_infos[index + 1]?.eta
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
          </div>
        </div>
        <span className='border border-secondary my-8 my-md-n8' />
        <div className='col-12 col-md-6 ps-6 fs-5 fs-xxl-7 fw-bold scroll-x'>
          <div className='col-12 d-flex border-bottom border-secondary'>
            <div className='col-3'>{intl.formatMessage({id: 'SHIP_FROM'})}</div>
            {showShipFrom && <span className='fs-5 fs-xxl-7 fw-boldest'>{showShipFrom}</span>}
          </div>
          <div className='col-12 d-flex border-bottom border-secondary mt-10'>
            <div className='col-3'>{intl.formatMessage({id: 'A1_SERVICE'})}</div>
            {a1Batch?.service && (
              <span className='fs-5 fs-xxl-7 fw-boldest'>{a1Batch?.service}</span>
            )}
          </div>
          <div className='d-flex mt-10'>
            <span className='min-w-50 d-flex justify-content-between'>
              <span className='col-3 border-bottom border-secondary'>
                {intl.formatMessage({id: 'MAWB'})}
              </span>
              <span className='flex-fill border-bottom border-secondary ps-2' />
              <span className='fw-boldest border-bottom border-secondary pe-4 text-truncate'>
                {a1Batch?.mawb}
              </span>
            </span>
            <span className='min-w-50 d-flex justify-content-between'>
              <span className='col-6 border-bottom border-secondary text-nowrap'>
                # {intl.formatMessage({id: 'OF_PALLETS'})}
              </span>
              <span className='flex-fill border-bottom border-secondary ps-2' />
              <span className='fw-boldest border-bottom border-secondary'>
                {formatNumber({number: a1Batch?.pallets, showDecimal: false})}
              </span>
            </span>
          </div>
          <div className='d-flex mt-10'>
            <span className='min-w-50 d-flex justify-content-between'>
              <span className='col-6 border-bottom border-secondary text-nowrap'>
                # {intl.formatMessage({id: 'OF_PARCELS'})}
              </span>
              <span className='flex-fill border-bottom border-secondary ps-2' />
              <span className='fw-boldest border-bottom border-secondary pe-4 text-truncate'>
                {formatNumber({number: a1Batch?.parcels, showDecimal: false})}
              </span>
            </span>
            <span className='min-w-50 d-flex justify-content-between'>
              <span className='col-6 border-bottom border-secondary'>
                {intl.formatMessage({id: 'KGS'})}
              </span>
              <span className='flex-fill border-bottom border-secondary ps-2' />
              <span className='fw-boldest border-bottom border-secondary'>
                {formatNumber({number: a1Batch?.weight})}
              </span>
            </span>
          </div>
          {isOrigin && (
            <>
              <div className='d-flex mt-10'>
                <span className='min-w-50 d-flex justify-content-between'>
                  <span className='border-bottom border-secondary text-nowrap'>
                    {intl.formatMessage({id: 'PICK_UP'})}
                  </span>
                  <span className='flex-fill border-bottom border-secondary ps-2' />
                  <span className='fw-boldest border-bottom border-secondary text-truncate'>
                    {formatNumber({number: a1Batch?.origin_pick_up})}
                    <span className='text-muted me-4 ms-2'>{intl.formatMessage({id: 'USD'})}</span>
                  </span>
                </span>
                <span className='min-w-50 d-flex justify-content-between'>
                  <span className='border-bottom border-secondary'>
                    {intl.formatMessage({id: 'CONSOLIDATION'})}
                  </span>
                  <span className='flex-fill border-bottom border-secondary' />
                  <span className='fw-boldest border-bottom border-secondary ps-2'>
                    {formatNumber({number: a1Batch?.origin_consolidation})}
                    <span className='text-muted ms-2'>{intl.formatMessage({id: 'USD'})}</span>
                  </span>
                </span>
              </div>
              <div className='d-flex mt-10'>
                <span className='min-w-50 d-flex justify-content-between'>
                  <span className='border-bottom border-secondary'>
                    {intl.formatMessage({id: 'FREIGHT'})}
                  </span>
                  <span className='flex-fill border-bottom border-secondary' />
                  <span className='fw-boldest border-bottom border-secondary ps-2'>
                    {formatNumber({number: a1Batch?.origin_freight})}
                    <span className='text-muted me-4 ms-2'>{intl.formatMessage({id: 'USD'})}</span>
                  </span>
                </span>
              </div>
            </>
          )}
          {isDestination && (
            <>
              <div className='d-flex mt-10'>
                <span className='col-6 d-flex justify-content-between'>
                  <span className='border-bottom border-secondary'>
                    {intl.formatMessage({id: 'CLEARANCE'})}
                  </span>
                  <span className='flex-fill border-bottom border-secondary' />
                  <span className='fw-boldest border-bottom border-secondary ps-2 text-truncate'>
                    {formatNumber({number: a1Batch?.destination_clearance})}
                    <span className='text-muted me-4 ms-2'>{intl.formatMessage({id: 'USD'})}</span>
                  </span>
                </span>
                <span className='col-6 d-flex justify-content-between'>
                  <span className='border-bottom border-secondary'>
                    {intl.formatMessage({id: 'DECONSOLIDATION'})}
                  </span>
                  <span className='flex-fill border-bottom border-secondary' />
                  <span className='fw-boldest border-bottom border-secondary ps-2'>
                    {formatNumber({number: a1Batch?.destination_deconsolidation})}
                    <span className='text-muted ms-2'>{intl.formatMessage({id: 'USD'})}</span>
                  </span>
                </span>
              </div>
              <div className='d-flex mt-10'>
                <span className='col-6 d-flex justify-content-between'>
                  <span className='border-bottom border-secondary'>
                    {intl.formatMessage({id: 'TRANSFER'})}
                  </span>
                  <span className='flex-fill border-bottom border-secondary' />
                  <span className='fw-boldest border-bottom border-secondary ps-2'>
                    {formatNumber({number: a1Batch?.destination_transfer})}
                    <span className='text-muted me-4 ms-2'>{intl.formatMessage({id: 'USD'})}</span>
                  </span>
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export {JourneyInfoCard}
