import {FC, useRef} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {KTSVG} from '../../components'
import {Button} from '../../widgets'

type Props = {
  className?: any
  event?: any
  config: {
    url: string
    hasSearch?: boolean
  }
  label?: any
  disabled?: boolean
}

const ButtonBack: FC<Props> = ({
  className,
  event,
  config = {url: '/', hasSearch: true},
  label,
  disabled = false,
}) => {
  const finalConfig = {
    ...config,
    hasSearch: config?.hasSearch ?? true,
  }

  const navigate = useNavigate()
  const location: any = useLocation()
  const searchRef = useRef(location?.state?.search || '')

  const handleBack = () => {
    const searchQuery = searchRef.current && finalConfig?.hasSearch ? searchRef.current : ''
    navigate(`${finalConfig.url}${searchQuery}`)
  }

  return (
    <Button
      label={label}
      event={event || handleBack}
      className={`${className} btn btn-light btn-sm fw-bolder me-5 ${!label ? 'btn-icon' : ''}`}
      icon={<KTSVG path='/media/gori/arrows/arrowLeft.svg' className='text-gray-700' />}
      disabled={disabled}
    />
  )
}

export {ButtonBack}
