import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {CSSTransition} from 'react-transition-group'
import {useA1BatchesProvider, useAuth, useDisabled} from '../../../../../../gori/providers'
import {Table, TableWrapper} from '../../../../../../gori/widgets'
import {A1BatchService, A1_CONSTANTS} from '../../../index'
import {useCancelToken, UsePermission} from '../../../../../../gori/hooks'
import {DEFAULT_PAGE, DEFAULT_PAGE_SIZE, FORMAT_DATE} from '../../../../../../gori/constants'
import {useParams, useSearchParams} from 'react-router-dom'
import {convertUserTimeZone} from '../../../../../../gori/helpers'
import {ConfirmActionModal} from '../../../../../../gori/components'
import InfiniteScroll from 'react-infinite-scroll-component'
import clsx from 'clsx'

type Props = {
  reloadTable?: boolean
}

const T86DocumentTable: React.FC<Props> = ({reloadTable = false}) => {
  const intl = useIntl()
  const {id} = useParams()
  const [searchParams] = useSearchParams()
  const {currentUser} = useAuth()
  const {setPageDisabled} = useDisabled()
  const {a1Batch} = useA1BatchesProvider()
  const {isCancel, newCancelToken} = useCancelToken()
  const [tableData, setTableData] = useState<any>([])
  const [documentId, setDocumentId] = useState<any>()
  const [hasLoadMore, setHasLoadMore] = useState<boolean>(false)
  const pageCurrent = useRef(Number(DEFAULT_PAGE))
  const {isOrigin} = UsePermission()
  const [pagination, setPagination] = useState<{
    page: number
    page_size: number
    total: number
    total_pages: number
  }>({
    page: 0,
    page_size: 0,
    total: 0,
    total_pages: Number(DEFAULT_PAGE),
  })

  const [loading, setLoading] = useState<{
    list: boolean
    update: boolean
    download: boolean
  }>({
    list: false,
    update: false,
    download: false,
  })

  const [showModal, setShowModal] = useState<{
    confirm: boolean
  }>({
    confirm: false,
  })

  const {PICK_UP_DROP_OFF} = A1_CONSTANTS.OPTIONS_JOURNEY_STATUS

  const canEdit = useMemo(() => {
    return isOrigin && PICK_UP_DROP_OFF.value[2] === a1Batch?.journey_status
  }, [isOrigin, PICK_UP_DROP_OFF, a1Batch?.journey_status])

  const setTable = useCallback(async () => {
    try {
      setPageDisabled(true)
      const config = {
        params: {
          page: searchParams.get('page') || DEFAULT_PAGE,
          page_size: searchParams.get('page_size') || DEFAULT_PAGE_SIZE,
          type: A1_CONSTANTS.DOCUMENT_TYPE.T86_DOCUMENTS.value,
        },
        cancelToken: newCancelToken(),
      }
      const response: any = await A1BatchService.t86Documents(id, config)
      if (response) {
        setTableData(response.documents)
        setPagination(response.pagination)
        setHasLoadMore(response.pagination.total_pages > 1)
      }
    } catch (error: any) {
      if (isCancel(error)) return
    } finally {
      setPageDisabled(false)
      setLoading((prev) => ({...prev, first: false}))
    }
  }, [id, isCancel, newCancelToken, searchParams, setPageDisabled])

  useEffect(() => {
    setTable()
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  useEffect(() => {
    if (reloadTable) {
      setTable()
      pageCurrent.current = Number(DEFAULT_PAGE)
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadTable])

  const columns = useMemo(() => {
    const _columns = [
      {
        id: 'created_at',
        Header: intl.formatMessage({id: 'DATE'}),
        headerClassName: 'min-w-200px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          return (
            row.original?.created_at &&
            convertUserTimeZone(row.original?.created_at, currentUser, FORMAT_DATE.DATE)
          )
        },
      },
      {
        id: 'name',
        Header: intl.formatMessage({id: 'DOWNLOAD_NAME'}),
        headerClassName: 'min-w-300px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          return row?.original?.name
        },
      },
      {
        id: 'action',
        Header: intl.formatMessage({id: 'ACTIONS'}),
        headerClassName: 'min-w-200px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          return (
            <>
              <div className='d-flex'>
                <a
                  className='text-teal cursor-pointer'
                  download={row?.original?.name}
                  href={row?.original?.path}
                  target='_blank'
                  rel='noreferrer'
                >
                  {intl.formatMessage({id: 'DOWNLOAD'})}
                </a>

                {tableData.length > 1 && canEdit && (
                  <div
                    className='px-10 text-danger cursor-pointer'
                    onClick={() => {
                      setDocumentId(row?.original?.id)
                      setShowModal((prev) => ({...prev, confirm: true}))
                    }}
                  >
                    {intl.formatMessage({id: 'DELETE'})}
                  </div>
                )}
              </div>
            </>
          )
        },
      },
    ]

    return _columns.filter((col) => col)
  }, [intl, currentUser, canEdit, tableData])

  const configParams = useMemo(
    () => ({
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
    }),
    []
  )

  const fetchMore = async () => {
    if (pageCurrent.current < pagination?.total_pages) {
      pageCurrent.current++

      try {
        setLoading((prev) => ({...prev, list: true}))
        const config = {
          params: {...configParams, page: pageCurrent.current},
          cancelToken: newCancelToken(),
        }
        const response: any = await A1BatchService.t86Documents(id, config)
        if (response) {
          setTableData((prev) => [...prev, ...response.documents])
          setPagination(response.pagination)
        }
      } catch (error: any) {
        if (isCancel(error)) return
      } finally {
        setLoading((prev) => ({...prev, list: false}))
      }
    } else {
      setHasLoadMore(false)
    }
  }

  const handleDelete = useCallback(async () => {
    try {
      setPageDisabled(true)
      const config = {cancelToken: newCancelToken()}
      const response: any = await A1BatchService.deleteT86Documents(documentId, config)
      if (response) {
        toast.success(response.message)
        setTable()
        pageCurrent.current = Number(DEFAULT_PAGE)
      }
    } catch (error: any) {
      if (isCancel(error)) return
    } finally {
      setPageDisabled(false)
      setShowModal((prev) => ({...prev, confirm: false}))
    }
  }, [isCancel, newCancelToken, setPageDisabled, documentId, setTable])

  return (
    <>
      {showModal.confirm && (
        <ConfirmActionModal
          show={showModal.confirm}
          title={intl.formatMessage({id: 'DELETE_T86_DOCUMENT'})}
          message={intl.formatMessage({id: 'DELETE_DOCUMENT_CONFIRM'})}
          loadingPrimary={loading.list}
          messagePrimary={intl.formatMessage({id: 'CONFIRM'})}
          messageSecondary={intl.formatMessage({id: 'CANCEL'})}
          handlePrimaryAction={handleDelete}
          handleSecondaryAction={() => setShowModal((prev) => ({...prev, confirm: false}))}
          handleClose={() => setShowModal((prev) => ({...prev, confirm: false}))}
        />
      )}
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <TableWrapper
          className={clsx({
            'mt-10': canEdit,
          })}
        >
          <InfiniteScroll
            dataLength={tableData}
            next={fetchMore}
            hasMore={hasLoadMore}
            height='50vh'
            loader={
              loading.list && (
                <div className='d-flex justify-content-center'>
                  <span className='spinner-border' />
                </div>
              )
            }
            endMessage={
              <p className='d-flex justify-content-center'>
                {intl.formatMessage({id: 'NO_MORE_ITEMS'})}
              </p>
            }
          >
            <Table
              columns={columns}
              data={tableData}
              pagination={pagination}
              tbodyClass='text-gray-600 fw-bold'
              usePagination={false}
            />
          </InfiniteScroll>
        </TableWrapper>
      </CSSTransition>
    </>
  )
}

export {T86DocumentTable}
