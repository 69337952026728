import {A1_CONSTANTS} from './constants'

const checkJourneyStatus = ({
  statusCurrent,
  statusCheck,
  comparisonType = 'greater_than_or_equal',
}: {
  statusCurrent: string
  statusCheck: string
  comparisonType?: 'less_than' | 'less_than_or_equal' | 'greater_than' | 'greater_than_or_equal'
}) => {
  const arrStatus = Object.values(A1_CONSTANTS.OPTIONS_JOURNEY_STATUS).flatMap(
    (status) => status.value
  )
  if (arrStatus.length === 0) return false
  const foundIndex = arrStatus.indexOf(statusCheck)
  if (foundIndex === -1) return false

  const comparisonRanges = {
    less_than: arrStatus.slice(0, foundIndex),
    less_than_or_equal: arrStatus.slice(0, foundIndex + 1),
    greater_than: arrStatus.slice(foundIndex + 1),
    greater_than_or_equal: arrStatus.slice(foundIndex),
  }
  return comparisonRanges[comparisonType].includes(statusCurrent)
}

export const A1Helpers = {
  checkJourneyStatus,
}
