import moment from 'moment'
import {useCallback} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {FORMAT_DATE, REGEX_EMAIL, REGEX_PASSWORD} from '../constants'
import {yupHelper} from '../helpers'

export const UseYupValidate = () => {
  const intl = useIntl()
  const yup = yupHelper(intl)

  const stringYup = (valueLabel, name, required = true) => {
    switch (valueLabel) {
      case 'no_limit':
        return yup.string({
          name: name,
          required: required,
          trim: true,
        })

      default:
        return yup.string({
          name: name,
          required: required,
          trim: true,
          max: {value: valueLabel, label: valueLabel},
        })
    }
  }

  const numberYup = {
    journey_info: useCallback(
      (config: {
        name: string
        required?: boolean
        integer?: boolean
        min?: {label: string; value: number}
        max?: null | {label: string; value: number}
        moreThan?: boolean
      }) => {
        const {
          name = '',
          required = true,
          integer = false,
          min = {
            value: 1,
            label: '1',
          },
          max = null,
          moreThan = false,
        } = config

        let yup = Yup.number()
          .nullable()
          .typeError(
            intl.formatMessage(
              {id: 'INPUT_CAN_ONLY_BE_FILLED_IN_DIGITS'},
              {input: intl.formatMessage({id: name})}
            )
          )
        if (moreThan) {
          yup = yup
            .positive()
            .moreThan(
              min.value,
              intl.formatMessage(
                {id: 'INPUT_MUST_BE_GREATER_THAN'},
                {input: intl.formatMessage({id: name}), min: min?.label}
              )
            )
        }
        if (!moreThan && min) {
          yup = yup.min(
            min?.value,
            intl.formatMessage(
              {id: 'INPUT_MUST_BE_GREATER_THAN_OR_EQUAL_TO'},
              {input: intl.formatMessage({id: name}), min: min?.label}
            )
          )
        }
        if (integer) {
          yup = yup.integer(
            intl.formatMessage(
              {id: 'INPUT_ONLY_ACCEPT_INTEGERS'},
              {input: intl.formatMessage({id: name})}
            )
          )
        }
        if (required) {
          yup = yup.required(
            intl.formatMessage({id: 'INPUT_IS_REQUIRED'}, {input: intl.formatMessage({id: name})})
          )
        }
        if (max) {
          yup = yup.max(
            max?.value,
            intl.formatMessage(
              {id: 'INPUT_MUST_NOT_BE_GREATER_THAN'},
              {input: intl.formatMessage({id: name}), max: max?.label}
            )
          )
        }
        return yup
      },
      [intl]
    ),
  }

  const dateYup = useCallback(
    (name, minDate = '2000-01-01T00:00:00Z', required = true, maxDate = '2099-12-31T23:59:59Z') => {
      let yup = Yup.date()
        .nullable()
        .typeError(
          intl.formatMessage({id: 'INPUT_IS_REQUIRED'}, {input: intl.formatMessage({id: name})})
        )
      if (minDate) {
        yup = yup.min(
          minDate,
          intl.formatMessage(
            {id: 'INPUT_MUST_BE_FROM_MIN_ONWARDS'},
            {
              input: intl.formatMessage({id: name}),
              min: moment(minDate).format(FORMAT_DATE.DATE),
            }
          )
        )
      }
      if (maxDate) {
        yup = yup.max(
          maxDate,
          intl.formatMessage(
            {id: 'INPUT_MUST_NOT_OVER_MAX'},
            {
              input: intl.formatMessage({id: name}),
              max: moment(maxDate).format(FORMAT_DATE.DATE),
            }
          )
        )
      }
      if (required) {
        yup = yup.required(
          intl.formatMessage({id: 'INPUT_IS_REQUIRED'}, {input: intl.formatMessage({id: name})})
        )
      }
      return yup
    },
    [intl]
  )

  const infoYup = {
    email: useCallback(
      (name = 'EMAIL', required = true) => {
        return yup.string({
          name: name,
          required: required,
          trim: true,
          max: {
            value: 100,
            label: 100,
          },
          regex: {
            value: REGEX_EMAIL,
            label: 'PLEASE_ENTER_VALID_EMAIL_ADDRESS',
          },
        })
      },
      [yup]
    ),
  }

  // BEGIN: auth
  const authYup = {
    password: useCallback(
      (required = true) => {
        return yup.string({
          name: 'PASSWORD',
          required: required,
          trim: true,
          max: {
            value: 50,
            label: 50,
          },
          regex: {
            value: REGEX_PASSWORD,
            label: 'PLEASE_ENTER_VALID_PASSWORD',
          },
        })
      },
      [yup]
    ),
    passwordConfirmation: useCallback(
      (required = true) => {
        return yup.string({
          name: 'CONFIRM_PASSWORD',
          required: required,
          trim: true,
          max: {
            value: 50,
            label: 50,
          },
          when: {
            target: 'password',
            is: (val: string) => !!(val && val.length > 0),
            then: Yup.string().oneOf(
              [Yup.ref('password')],
              intl.formatMessage({id: 'PASSWORD_AND_CONFIRM_PASSWORD_DIDNT_MATCH'})
            ),
          },
        })
      },
      [intl, yup]
    ),
  }
  // END: auth

  return {
    stringYup,
    numberYup,
    dateYup,
    infoYup,
    authYup,
  }
}
