import clsx from 'clsx'
import {AsideMenuLayout} from '../../../layout'

const AsideSubLayout = () => {
  return (
    <div
      className={clsx(
        'py-3 py-xxl-9 px-9 px-xxl-0 d-flex flex-column justify-content-between mt-xxl-1 h-100'
      )}
    >
      <div className='mb-6 mb-xxl-20'>
        <div className='mt-xxl-30'></div>
        <div className='aside--sub__center'>
          <AsideMenuLayout className={'aside--sub'} />
        </div>
      </div>
    </div>
  )
}

export {AsideSubLayout}
